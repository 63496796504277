import * as R from 'ramda';
import {compose} from 'ramda';
import {applyMiddleware, createStore} from 'redux';
import {apiMiddleware} from 'redux-api-middleware';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import * as Cookies from 'js-cookie';

import {loggingMiddleware} from './middleware/loggingMiddleware';
import {noOpMiddleware} from './middleware/noOpMiddleware';
import rootReducer from './appState/rootReducer';
import INITIAL_APP_STATE from './appState/initialAppState';
import {AUTH} from './cookies/keys';

const isDevEnv = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');

const logger   = isDevEnv ? loggingMiddleware : noOpMiddleware;
const composeEnhancers = (isDevEnv && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

let initialAppState = INITIAL_APP_STATE;
const prevAuth      = Cookies.getJSON(AUTH);


// The user last signed in less than a week ago
if (prevAuth && prevAuth.lastSignIn && (new Date() - new Date(prevAuth.lastSignIn)) / (1000 * 3600 * 24) < 7) {
  initialAppState = R.assoc('login', prevAuth, initialAppState);
}

const store = createStore(
  rootReducer,
  initialAppState,
  composeEnhancers(
    applyMiddleware(
      logger, // Keep first
      apiMiddleware, // REST middleware function to make REST requests for us and forward on to Reducers
      reduxImmutableStateInvariant(), // Errors in debug if store state is mutated in dispatch/between dispatches.
    ),
  ),
);

export default store;