import * as R from 'ramda';
import actionTypes from '../appState/actionTypes';
import {UNAUTHORIZED_ROLE} from '../constants/workerRoles';


export default (state, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_RESPONSE:
    case actionTypes.LOGIN_REQUEST:
    case actionTypes.LOGIN_ERROR:
      return R.mergeDeepRight(
        state,
        {
          login: {
            lastName:    action.meta.lastName,
            ssn:         action.meta.ssn,
            isSignedIn:  action.type === actionTypes.LOGIN_RESPONSE,
            isSigningIn: action.type === actionTypes.LOGIN_REQUEST && !action.error,
            lastSignIn:  action.type === actionTypes.LOGIN_RESPONSE ? new Date() : undefined,
            signInError: (action.error || action.type === actionTypes.LOGIN_ERROR)
                         && 'Login failed, please verify your credentials.',
            workerRole:  action.type === actionTypes.LOGIN_RESPONSE && !!action.payload && action.payload.RoleName,
          },
        },
      );

    case actionTypes.LOGOUT_REQUEST:
      return R.merge(
        state,
        {
          login: {
            isSignedIn:  false,
            isSigningIn: false,
            lastSignIn:  undefined,
            signInError: undefined,
            workerRole:  UNAUTHORIZED_ROLE,
          },
        },
      );

    default:
      return state;
  }
}
