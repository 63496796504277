import {RSAA} from 'redux-api-middleware';
import {ARTICLES, ADMIN_ARTICLES} from '../constants/endpoints';
import actionTypes from '../appState/actionTypes';
import {ADMIN_ROLE} from '../constants/workerRoles';

export const editArticle       = article => ({type: actionTypes.SET_ARTICLE_TO_EDIT, article});
export const resetArticle      = () => ({type: actionTypes.RESET_EDIT_ARTICLE});
export const changeArticlePage = pageIndex => ({type: actionTypes.ARTICLE_LIST_CHANGE_PAGE, pageIndex});

/**
 * Delegate to the redux api middleware to fetch the list of articles.
 */
export const fetchArticles = () => ({
  [RSAA]: {
    method:   'GET',
    headers:  {
      'content-type': 'application/json',
    },
    endpoint: state => state.login.workerRole === ADMIN_ROLE ? ADMIN_ARTICLES : ARTICLES,
    types:    [actionTypes.ARTICLE_LIST_REQUEST, actionTypes.ARTICLE_LIST_RESPONSE, actionTypes.ARTICLE_LIST_ERROR],
    // TODO: Add bailout that checks for any pending requests of this type
  },
});

/**
 * Delegate to the redux api middleware to fetch the given article.
 */
export const fetchOneArticle = (articleId) => ({
  [RSAA]: {
    method:   'GET',
    headers:  {
      'content-type': 'application/json',
    },
    endpoint: `${ARTICLES}?id=${articleId}`,
    types:    [actionTypes.ARTICLE_GET_REQUEST, actionTypes.ARTICLE_GET_RESPONSE, actionTypes.ARTICLE_GET_ERROR],
    // TODO: Add bailout that checks for any pending requests of this type
  },
});

/**
 * Delegate to the redux api middleware to update the given article.
 *
 * @param article
 */
export const updateArticle = article => ({
  [RSAA]: {
    method:   'PUT',
    headers:  {
      'content-type': 'application/json',
    },
    endpoint: ARTICLES,
    body:     JSON.stringify(article),
    types:    [
      actionTypes.ARTICLE_UPDATE_REQUEST,
      actionTypes.ARTICLE_UPDATE_RESPONSE,
      actionTypes.ARTICLE_UPDATE_ERROR,
    ],
    // TODO: Add bailout that checks for any pending requests of this type
  },
});

/**
 * Delegate to the redux api middleware to create a new record for the given article.
 *
 * @param article
 */
export const createArticle = article => ({
  [RSAA]: {
    method:   'POST',
    headers:  {
      'content-type': 'application/json',
    },
    endpoint: ARTICLES,
    body:     JSON.stringify(article),
    types:    [
      actionTypes.ARTICLE_CREATE_REQUEST,
      actionTypes.ARTICLE_CREATE_RESPONSE,
      actionTypes.ARTICLE_CREATE_ERROR,
    ],
    // TODO: Add bailout that checks for any pending requests of this type
  },
});

/**
 * Delegate to the redux api middleware to delete the given article.
 *
 * @param article
 */
export const deleteArticle = article => ({
  [RSAA]: {
    method:   'DELETE',
    headers:  {
      'content-type': 'application/json',
    },
    endpoint: ARTICLES,
    body:     JSON.stringify(article),
    types:    [
      actionTypes.ARTICLE_DELETE_REQUEST,
      actionTypes.ARTICLE_DELETE_RESPONSE,
      actionTypes.ARTICLE_DELETE_ERROR,
    ],
    // TODO: Add bailout that checks for any pending requests of this type
  },
});
