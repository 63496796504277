import * as R from 'ramda';
import React from 'react';
import {connect} from 'react-redux';
import {Route, Switch, withRouter} from 'react-router';
import {BrowserRouter} from 'react-router-dom';
import {makeLoginAction} from './actions/loginActions';
import './App.css';
import ArticleEdit from './components/ArticleEdit/ArticleEdit';
import ArticleList from './components/ArticleList/ArticleList';
import DocumentsPage from './documents/DocumentsPage';
import InstallPage from './components/InstallPage/InstallPage';
import LandingPage from './components/LandingPage/LandingPage';
import ModalContainer from './components/Modal/ModalContainer';
import {PrivateRoute} from './components/PrivateRoute';
import SignOut from './components/SignOut/SignOut';
import HelpPage from './components/HelpPage/HelpPage';
import modalConstants from './constants/modalConstants';
import {
  ARTICLES_CREATE,
  ARTICLES_EDIT_ROUTE,
  ARTICLES_LIST,
  DOCUMENTS,
  HOME,
  INSTALL,
  PUBLIC_PAGES,
  SIGN_OUT,
  HELP, ANALYTICS,
} from './constants/routes';
import CookieMapper from './cookies/CookieMapper';
import Analytics from './components/Analytics/Analytics';

const ArticleEditExisting  =
  (props) => <ArticleEdit {...props} isEdit={true} />;

export default withRouter(
  connect(
    (state, props) => R.merge(
      props,
      {
        isSignedIn:  state.login.isSignedIn,
        signInError: state.login.signInError,
        modalType:   state.modal.modalType,
        modalProps:  state.modal.modalProps,
      },
    ),
    (dispatch, props) => R.merge(
      props,
      {
        signIn: (lastName, ssn) => dispatch(makeLoginAction(lastName, ssn)),
      },
    ),
  )(
    class App extends React.PureComponent {
      render() {
        const {isSignedIn, signInError, signIn, location, modalType, modalProps} = this.props;

        const signInProps = {
          error:    signInError,
          onSignIn: signIn,
        };

        return (
          <BrowserRouter>
            <div style={{overflow: isSignedIn ? 'auto' : 'hidden'}}>
              <CookieMapper/>
              <Switch> {/* Switch => match only one */}
                <Route path={INSTALL} component={InstallPage}/>
                <PrivateRoute isSignedIn={isSignedIn} path={ARTICLES_LIST} component={ArticleList}/>
                <PrivateRoute isSignedIn={isSignedIn} path={ARTICLES_EDIT_ROUTE} component={ArticleEditExisting} />
                <PrivateRoute isSignedIn={isSignedIn} path={ARTICLES_CREATE} component={ArticleEdit}/>
                <PrivateRoute isSignedIn={isSignedIn} path={DOCUMENTS} component={DocumentsPage}/>
                <PrivateRoute isSignedIn={isSignedIn} path={ANALYTICS} component={Analytics}/>
                <Route path={HELP} component={HelpPage}/>
                <Route path={SIGN_OUT} component={SignOut}/>
                <Route path={HOME} component={LandingPage}/>
              </Switch>
              { // Show login modal if not logged in
                !isSignedIn
                && !R.contains(location.pathname, PUBLIC_PAGES)
                && <ModalContainer modalType={modalConstants.SIGN_IN_MODAL}
                                   modalProps={signInProps}/>
              }
              {
                !!modalType && <ModalContainer modalType={modalType} modalProps={modalProps}/>
              }
            </div>
          </BrowserRouter>
        );
      }
    },
  )
);
