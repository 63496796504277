import articleData from '../reducers/articleData';
import articleList from '../reducers/articleList';
import analytics from '../reducers/analytics';
import login from '../reducers/login';
import modal from '../reducers/modal';
import {deleteFileReducer} from '../documents/deleteFileAction';
import {upsertFileReducer} from '../documents/upsertFileAction';
import {deleteFolderReducer} from '../documents/deleteFolderAction';
import {fetchDocumentsReducer} from '../documents/fetchDocumentsAction';
import {updateFolderReducer} from '../documents/updateFolderAction';


export default (state, action) => [
  login,
  modal,
  articleList,
  articleData,
  analytics,
  fetchDocumentsReducer,
  deleteFileReducer,
  upsertFileReducer,
  deleteFolderReducer,
  updateFolderReducer,
].reduce((running, next) => next(running, action), state);

