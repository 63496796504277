import * as R from 'ramda';
import actionTypes from '../appState/actionTypes';


export default (state, action) => {
  switch (action.type) {
    case actionTypes.ANALYTICS_GET_REQUEST:
      return R.mergeDeepRight(
        state,
        {
          analytics: {
            isFetching: true,
            errorMessage: '',
          },
        },
      );

    case actionTypes.ANALYTICS_GET_ERROR:
      return R.mergeDeepRight(
        state,
        {
          analytics: {
            isFetching: false,
            errorMessage: "Could not load analytics. " + (action.payload ? action.payload.message : "") || "",
          },
        },
      );
    
    case actionTypes.ANALYTICS_GET_RESPONSE:
      return R.mergeDeepRight(
        state,
        {
          analytics: {
            isFetching:   false,
            errorMessage: '',
            events:       action.payload,
          },
        },
      );
    case actionTypes.ANALYTICS_CLEAR_EVENTS:
      return R.mergeDeepRight(
        state,
        {
          analytics: {
            isFetching:   false,
            errorMessage: '',
            events:       [],
          },
        },
      );
    default:
      return state;
  }
}
