import React from "react";
import PropTypes from "prop-types";

/**
 * A Component that shows an error message on a red background with an optional OK button
 * @param message - The message to show in the view.
 * @param handleErrorOk - If provided, will show an OK button for which this is triggered
 * @returns {*}
 * @constructor
 */
export const ErrorMessage = ({message, handleErrorOk}) => (<div className="alert alert-danger">
    <span style={{
        fontSize: '20px',
    }}>{message}</span>
    <button className={`.btn-default`}
            type="button"
            hidden={!handleErrorOk}
            onClick={handleErrorOk}>
        OK
    </button>
</div>);

ErrorMessage.propTypes = {
    message: PropTypes.string.isRequired,
    handleErrorOk: PropTypes.func,
};
