import * as R from 'ramda';
import actionTypes from '../appState/actionTypes';


export default (state, action) => {
  switch (action.type) {
    case actionTypes.SET_ARTICLE_TO_EDIT:
      return R.assoc(
        'articleData',
        {
          updateSuccess: false,
          item:          action.article,
          errorMessage:  '',
        },
        state,
      );

    case actionTypes.RESET_EDIT_ARTICLE:
      return R.assoc(
        'articleData',
        {
          updateSuccess: true,
          item:          null,
          errorMessage:  '',
        },
        state,
      );

    case actionTypes.ARTICLE_CREATE_RESPONSE:
    case actionTypes.ARTICLE_UPDATE_RESPONSE:
    case actionTypes.ARTICLE_DELETE_RESPONSE:
      return R.mergeDeepRight(
        state,
        {
          articleData: {
            updateSuccess: true,
            errorMessage:  '',
          },
        },
      );

    case actionTypes.ARTICLE_CREATE_ERROR:
    case actionTypes.ARTICLE_UPDATE_ERROR:
    case actionTypes.ARTICLE_DELETE_ERROR:
      return R.mergeDeepRight(
        state,
        {
          articleData: {
            updateSuccess: false,
            errorMessage:  action.error,
          },
        },
      );

    default:
      return state;
  }
}
