import * as R from 'ramda';
import React from 'react';
import {connect} from 'react-redux';
import {COLORS} from '../colors';
import Navigation from '../components/Navigation/Navigation';
import './documentsPage.css';
import FolderView from './FolderView';
import UpsertDocumentView from './UpsertDocumentView';
import actionTypes from '../appState/actionTypes';
import {fetchDocumentsAction} from './fetchDocumentsAction';
import {ConfirmationModal} from '../core/ConfirmationModal';
import {getLoading, getError} from '../appState/apiState';
import {upsertFileAction} from './upsertFileAction';
import {deleteFileAction} from './deleteFileAction';
import {deleteFolderAction} from './deleteFolderAction';
import UpdateFolderView from './UpdateFolderView';
import {updateFolderAction} from './updateFolderAction';
import {EDITOR_ROLE, ADMIN_ROLE} from '../constants/workerRoles';
import {cannonicalPath} from './documents';
import {isNilOrEmpty} from '../core/language';

const makeTab = (
  text,
  isActive,
  onClick,
) => <span key={text} onClick={onClick} className={`tab ${isActive ? 'selected' : ''}`}>{text}</span>;


const fileCategory = {
  IT:      'Information Technology',
  HR:      'Human Resources',
  SAFETY:  'Safety',
  GENERAL: 'General',
};

const allCategories = [fileCategory.IT, fileCategory.HR, fileCategory.SAFETY, fileCategory.GENERAL];


export default connect(
  (state, props) => R.merge(props, {
    canEdit: state.login && state.login.isSignedIn && R.contains(state.login.workerRole, [ADMIN_ROLE, EDITOR_ROLE]),

    // File Loading
    loading: getLoading(state, actionTypes.FETCH_DOCUMENTS_REQUEST, actionTypes.FETCH_DOCUMENTS_REQUEST),
    error:   getError(state, actionTypes.FETCH_DOCUMENTS_REQUEST, actionTypes.FETCH_DOCUMENTS_REQUEST),
    it:      R.path(['documents', 'it'], state),
    hr:      R.path(['documents', 'hr'], state),
    safety:  R.path(['documents', 'safety'], state),
    general: R.path(['documents', 'general'], state),

    // File CRUD
    fileUpsertLoading: R.find(obj => obj.action === actionTypes.UPSERT_DOCUMENT_REQUEST, state.loading),
    fileUpsertError:   R.find(obj => obj.action === actionTypes.UPSERT_DOCUMENT_REQUEST, state.error),
    fileDeleteLoading: R.find(obj => obj.action === actionTypes.DELETE_DOCUMENT_REQUEST, state.loading),
    fileDeleteError:   R.find(obj => obj.action === actionTypes.DELETE_DOCUMENT_REQUEST, state.error),

    // Folder CRUD
    folderUpdateLoading: R.find(obj => obj.action === actionTypes.UPDATE_FOLDER_REQUEST, state.loading),
    folderUpdateError:   R.find(obj => obj.action === actionTypes.UPDATE_FOLDER_REQUEST, state.error),
    folderDeleteLoading: R.find(obj => obj.action === actionTypes.DELETE_FOLDER_REQUEST, state.loading),
    folderDeleteError:   R.find(obj => obj.action === actionTypes.DELETE_FOLDER_REQUEST, state.error),
  }),
  (dispatch, props) => R.merge(props, {
    fetchDocs:    () => dispatch(fetchDocumentsAction()),
    upsertFile:   file => dispatch(upsertFileAction(file)),
    deleteFile:   fileId => dispatch(deleteFileAction(fileId)),
    deleteFolder: (path, topic) => dispatch(deleteFolderAction(path, topic)),
    updateFolder: diff => dispatch(updateFolderAction(diff.oldPath, diff.newPath, diff.oldTopic, diff.newTopic)),
  }),
)(
  class DocumentsPage extends React.PureComponent {
    state = {
      selectedTab:      allCategories[0],
      showUpsertModal:  false,
      showDeleteModal:  false,
      fileItemToUpdate: undefined,
      uploadPath:       undefined,
    };


    componentDidMount() {
      this.props.fetchDocs();
    }


    componentWillUpdate(nextProps, nextState, nextContext) {
      // We're not loading the file hierarchies, and something stopped updating -- re-fetch file hierarchy
      if (!this.props.loading
          && !nextProps.loading
          && (
            (this.props.fileUpsertLoading && !nextProps.fileUpsertLoading)
            || (this.props.fileDeleteLoading && !nextProps.fileDeleteLoading)
            || (this.props.folderUpdateLoading && !nextProps.folderUpdateLoading)
            || (this.props.folderDeleteLoading && !nextProps.folderDeleteLoading)
          )
      ) {
        this.props.fetchDocs();
      }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
      if (isNilOrEmpty(this.props.it)
          && isNilOrEmpty(this.props.hr)
          && isNilOrEmpty(this.props.safety)
          && isNilOrEmpty(this.props.general)
      ) {
        this.props.fetchDocs();
      }
    }


    makeOnTabClick = tabName => () => this.setState(prev => R.assoc('selectedTab', tabName, prev));


    hideModals = () => this.setState(prev => R.merge(prev, {showUpsertModal: false, showDeleteModal: false}));


    onEditFile = file => this.setState(
      prev => R.merge(prev, {showUpsertModal: true, fileItemToUpdate: file, uploadPath: undefined}),
    );


    onDeleteFile = file => this.setState(
      prev => R.merge(prev, {showDeleteModal: true, fileItemToUpdate: file, uploadPath: undefined}),
    );


    onUploadFile = (uploadPath) => this.setState(
      prev => R.merge(prev, {showUpsertModal: true, fileItemToUpdate: undefined, uploadPath: uploadPath}),
    );


    onDeleteCancel = () => this.hideModals();
    onUpsertCancel = () => this.hideModals();


    onDeleteSubmit = () => {
      this.hideModals();

      if (this.state.fileItemToUpdate.isFolder) {
        const folderPath = cannonicalPath(this.state.fileItemToUpdate);
        this.props.deleteFolder(folderPath, this.state.fileItemToUpdate.topic);

      } else {
        this.props.deleteFile(this.state.fileItemToUpdate.Id);
      }
    };


    onUpsertSubmit = (fsItem) => {
      this.hideModals();

      if (this.state.fileItemToUpdate && R.equals(fsItem, this.state.fileItemToUpdate)) {
        return;
      }

      this.props.upsertFile(fsItem);
    };


    onUpdateFolderSubmit = folderDiff => {
      this.hideModals();

      // Ignore the update if nothing changed
      if (R.equals(folderDiff.oldPath, folderDiff.newPath) && R.equals(folderDiff.oldTopic, folderDiff.newTopic)) {
        return;
      }

      this.props.updateFolder(folderDiff);
    };


    render = () => <div>
      <Navigation location={this.props.location}/>
      <div className="container" style={{
        marginTop: '20px',
      }}>
        <div className='shadow' style={{
          marginBottom: '20px',
        }}>
          <div style={{
            display:       'flex',
            flexDirection: 'row',
            flexWrap:      'wrap',
            borderBottom:  `3px solid ${COLORS.KODIAK_BLUE}`,
          }}>
            {
              allCategories.map(
                category => makeTab(category, category === this.state.selectedTab, this.makeOnTabClick(category)),
              )
            }
          </div>
          {/* IT */}
          <FolderView className={this.state.selectedTab === fileCategory.IT ? '' : 'hidden'}
                      loading={this.props.loading}
                      rootDir={this.props.it}
                      canEdit={this.props.canEdit}
                      onUploadClick={this.onUploadFile}
                      onEdit={this.onEditFile}
                      onDelete={this.onDeleteFile}
                      error={R.path(['error', 'message'], this.props)}/>
          {/* HR */}
          <FolderView className={this.state.selectedTab === fileCategory.HR ? '' : 'hidden'}
                      loading={this.props.loading}
                      rootDir={this.props.hr}
                      canEdit={this.props.canEdit}
                      onUploadClick={this.onUploadFile}
                      onEdit={this.onEditFile}
                      onDelete={this.onDeleteFile}
                      error={R.path(['error', 'message'], this.props)}/>
          {/* Safety */}
          <FolderView className={this.state.selectedTab === fileCategory.SAFETY ? '' : 'hidden'}
                      loading={this.props.loading}
                      rootDir={this.props.safety}
                      canEdit={this.props.canEdit}
                      onUploadClick={this.onUploadFile}
                      onEdit={this.onEditFile}
                      onDelete={this.onDeleteFile}
                      error={R.path(['error', 'message'], this.props)}/>
          {/* General */}
          <FolderView className={this.state.selectedTab === fileCategory.GENERAL ? '' : 'hidden'}
                      loading={this.props.loading}
                      rootDir={this.props.general}
                      canEdit={this.props.canEdit}
                      onUploadClick={this.onUploadFile}
                      onEdit={this.onEditFile}
                      onDelete={this.onDeleteFile}
                      error={R.path(['error', 'message'], this.props)}/>
        </div>
      </div>
      { // Upsert File Modal
        this.state.showUpsertModal
        && (
          R.path(['fileItemToUpdate', 'isFolder'], this.state)
          ? <UpdateFolderView folder={this.state.fileItemToUpdate}
                              it={this.props.it}
                              hr={this.props.hr}
                              safety={this.props.safety}
                              general={this.props.general}
                              onSubmit={this.onUpdateFolderSubmit}
                              onCancel={this.onUpsertCancel}/>
          : <UpsertDocumentView loading={false}
                                it={this.props.it}
                                hr={this.props.hr}
                                safety={this.props.safety}
                                general={this.props.general}
                                title={this.state.fileItemToUpdate ? 'Update File' : 'Upload New File'}
                                uploadPath={this.state.uploadPath}
                                file={
                                  this.state.fileItemToUpdate
                                  ? {
                                      id:       this.state.fileItemToUpdate.Id,
                                      fileName: this.state.fileItemToUpdate.FileName,
                                      topic:    this.state.fileItemToUpdate.Topic,
                                      path:     this.state.fileItemToUpdate.Path,
                                      url:      this.state.fileItemToUpdate.Url,
                                    }
                                  : undefined
                                }
                                onCancel={this.onUpsertCancel}
                                onSubmit={this.onUpsertSubmit}/>
        )
      }
      { // Delete Confirmation Dialog
        this.state.showDeleteModal
        && this.state.fileItemToUpdate
        && <ConfirmationModal title='Delete?'
                              message={
                                `Are you want to delete this ${
                                  this.state.fileItemToUpdate.isFolder ? 'folder' : 'file'
                                  }: ${this.state.fileItemToUpdate.name || this.state.fileItemToUpdate.FileName}?`
                              }
                              positiveText='Delete'
                              negativeText='Cancel'
                              onNegativeClick={this.onDeleteCancel}
                              onPositiveClick={this.onDeleteSubmit}/>
      }
    </div>;
  },
);
