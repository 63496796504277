import {DELETE_DOC} from '../constants/endpoints';
import actionTypes from '../appState/actionTypes';
import {RSAA} from 'redux-api-middleware';
import {getLoading, addLoading, addError, removeErrorAndLoading, getApiError} from '../appState/apiState';
import * as R from 'ramda';


const actions = [
  actionTypes.DELETE_DOCUMENT_REQUEST,
  actionTypes.DELETE_DOCUMENT_RESPONSE,
  actionTypes.DELETE_DOCUMENT_ERROR,
];


export const deleteFileAction = (fileId) => ({
  [RSAA]: {
    method:   'POST',
    headers:  {'content-type': 'application/json'},
    endpoint: DELETE_DOC,
    body:     JSON.stringify({fileId: fileId}),
    types:    [
      {
        type: actionTypes.DELETE_DOCUMENT_REQUEST,
        meta: {fileId: fileId},
      },
      {
        type: actionTypes.DELETE_DOCUMENT_RESPONSE,
        meta: {fileId: fileId},
      },
      {
        type: actionTypes.DELETE_DOCUMENT_ERROR,
        meta: {fileId: fileId},
      },
    ],
    bailout:  state => getLoading(state, fileId, actionTypes.DELETE_DOCUMENT_REQUEST),
  },
});


export const deleteFileReducer = (state, action) => {
  if (!actions.includes(action.type)) {
    return state;
  }

  const fileId    = R.path(['meta', 'fileId'], action);
  const payload   = R.path(['payload'], action);
  const error     = getApiError(action, payload);
  const isLoading = action.type === actionTypes.DELETE_DOCUMENT_REQUEST && !error;

  if (isLoading) {
    return addLoading(state, fileId, actionTypes.DELETE_DOCUMENT_REQUEST);
  }

  if (error) {
    return addError(state, fileId, actionTypes.DELETE_DOCUMENT_REQUEST, `Failed to delete folder: ${error}`);
  }

  // TODO: remove the file from app state
  return removeErrorAndLoading(state, fileId, actionTypes.DELETE_DOCUMENT_REQUEST);
};
