import * as R from 'ramda';
import actionTypes from '../appState/actionTypes';
import searchType from '../constants/searchTypes';


const initialState = {
  isFetching:  false,
  items:       [],
  currentPage: 0,
  searchTerm:  '',
};

const normalizeValue = value => typeof (value) === 'string' ? value.trim() : value;
const normalizeKey   = key => `${key.charAt(0).toLocaleLowerCase()}${key.length > 1 ? key.substr(1) : ''}`;

const normalizeArticle = article => R.pipe(normalizeKeyCapitalization, updateLegacyArticle)(article);
const normalizeKeyCapitalization = article => Object.keys(article)
                                          .reduce(
                                            (running, next) => R.merge(running, {
                                              [normalizeKey(next)]: normalizeValue(article[next]),
                                            }),
                                            {},
                                          );
const updateLegacyArticle = article => {
  if (article.articleContentType)
      return article;

  let articleContentType = 'announcement';

  if (article.titleImage && article.url) {
      articleContentType = 'picturelink';
  } else if (article.titleImage) {
      articleContentType = 'picture';
  } else if (article.url) {
      articleContentType = 'announcementlink';
  }

  return R.assoc('articleContentType', articleContentType, article);
}


const byCreatedDate = R.descend(R.prop('createdOn'));

export default (state, action) => {
  switch (action.type) {
    case actionTypes.ARTICLE_LIST_REQUEST:
    case actionTypes.ARTICLE_GET_REQUEST:
      return R.mergeDeepRight(
        state,
        {
          articleList: {
            isFetching: true,
            errorMessage: '',
          },
        },
      );

    case actionTypes.ARTICLE_LIST_ERROR:
    case actionTypes.ARTICLE_GET_ERROR:
      return R.mergeDeepRight(
        state,
        {
          articleList: {
            isFetching: false,
            errorMessage: "Could not load articles. " + (action.payload ? action.payload.message : "") || "",
          },
        },
      );

    case actionTypes.ARTICLE_GET_RESPONSE:
      const new_article = [ normalizeArticle(action.payload) ];

      return R.mergeDeepRight(
        state,
        {
          articleList: {
            isFetching:   false,
            errorMessage: '',
            items:        new_article,
          },
        },
      );

    case actionTypes.ARTICLE_LIST_RESPONSE:
    case actionTypes.ARTICLE_UPDATE_RESPONSE:
    case actionTypes.ARTICLE_CREATE_RESPONSE:
    case actionTypes.ARTICLE_DELETE_RESPONSE:
      const articles = action.payload ? R.sort(byCreatedDate, action.payload.map(normalizeArticle)) : [];

      return R.mergeDeepRight(
        state,
        {
          articleList: {
            isFetching:   false,
            errorMessage: '',
            items:        articles,
          },
        },
      );

    case actionTypes.ARTICLE_LIST_CHANGE_PAGE:
      return R.mergeDeepRight(
        state,
        {
          articleList: {
            currentPage: action.pageIndex,
          },
        },
      );

    case actionTypes.ENTER_SEARCH:
      return action.searchType === searchType.ARTICLE_SEARCH
             ? R.mergeDeepRight(
                 state,
                 {
                   articleList: {
                     searchTerm:  action.searchValue,
                     currentPage: initialState.currentPage,
                   },
                 },
               )
             : state;
    default:
      return state;
  }
}
