import * as React from 'react';
import PropTypes from 'prop-types';


export const ConfirmationModal = (props) => (
  <div style={{
    position:        'fixed',
    zIndex:          100,
    width:           '100vw',
    height:          '100vh',
    top:             0,
    left:            0,
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'center',
    backgroundColor: '#000000AA',
  }}>
    <div style={{
      width:           '400px',
      margin:          'auto',
      padding:         '20px',
      backgroundColor: 'white',
    }}>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        {/* Title */}
        <h2>{props.title}</h2>

        {/* Message */}
        <p>{props.message}</p>

        {/* Submit/Cancel buttons */}
        <div style={{
          display:       'flex',
          flexDirection: 'row',
          marginTop:     '5px',
        }}>
          <button onClick={props.onPositiveClick}
                  className='btn btn-primary'>
            {props.positiveText}
          </button>
          <button type='cancel'
                  onClick={props.onNegativeClick}
                  className='btn btn-secondary'
                  style={{marginLeft: '10px'}}>
            {props.negativeText}
          </button>
        </div>
      </div>
    </div>
  </div>
);


ConfirmationModal.propTypes = {
  title:           PropTypes.string,
  message:         PropTypes.string,
  positiveText:    PropTypes.string,
  negativeText:    PropTypes.string,
  onPositiveClick: PropTypes.func,
  onNegativeClick: PropTypes.func,
};
