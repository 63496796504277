import React from 'react';
import PropTypes from 'prop-types';

const dateFormatOptions = 
  {
      year: 'numeric', month: 'long', day: '2-digit',
      hour: 'numeric', minute: 'numeric'
  };

export const DateDisplay = (
  {
    value,
    published,
  },
) => <span style={{
  color: new Date(value) > new Date() ? '#518087' : 'black',
}}>
  {
    new Date(value) > new Date()
      ?
      'Publish on: '
      : (published ? 'Published: ' : 'Should have been published on: ')
  }
  {
    new Intl.DateTimeFormat('en-US', dateFormatOptions).format(new Date(value))
  }
</span>;

DateDisplay.propTypes = {
  value: PropTypes.string.isRequired,
};
