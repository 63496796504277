import * as R from 'ramda';
import actionTypes from '../appState/actionTypes';
import {deserializeDocTree} from './documents';
import {DOCUMENTS} from '../constants/endpoints';
import {RSAA} from 'redux-api-middleware';
import {addLoading, addError, removeErrorAndLoading, getLoading, getApiError} from '../appState/apiState';


const actions = [
  actionTypes.FETCH_DOCUMENTS_REQUEST,
  actionTypes.FETCH_DOCUMENTS_RESPONSE,
  actionTypes.FETCH_DOCUMENTS_ERROR,
];


export const fetchDocumentsAction = () => ({
  [RSAA]: {
    method:   'GET',
    headers:  {'content-type': 'application/json'},
    endpoint: DOCUMENTS,
    types:    actions,
    bailout:  state => !!getLoading(state, actionTypes.FETCH_DOCUMENTS_REQUEST, actionTypes.FETCH_DOCUMENTS_REQUEST),
  },
});


export const fetchDocumentsReducer = (state, action) => {
  if (!actions.includes(action.type)) {
    return state;
  }

  const payload   = R.path(['payload'], action);
  const error     = getApiError(action, payload);
  const isLoading = action.type === actionTypes.FETCH_DOCUMENTS_REQUEST && !error;

  // Remove error; adding loading
  if (isLoading) {
    return addLoading(state, actionTypes.FETCH_DOCUMENTS_REQUEST, actionTypes.FETCH_DOCUMENTS_REQUEST);
  }

  // Remove loading; add error
  if (error) {
    return addError(
      state,
      actionTypes.FETCH_DOCUMENTS_REQUEST,
      actionTypes.FETCH_DOCUMENTS_REQUEST,
      `Failed to fetch documents: ${error}`,
    );
  }

  // Remove loading/error; add documents
  return R.pipe(
    R.assocPath(['documents', 'general'], deserializeDocTree(payload.filter(file => file.Topic === 'General'))),
    R.assocPath(['documents', 'safety'], deserializeDocTree(payload.filter(file => file.Topic === 'Safety'))),
    R.assocPath(['documents', 'hr'], deserializeDocTree(payload.filter(file => file.Topic === 'HR'))),
    R.assocPath(['documents', 'it'], deserializeDocTree(payload.filter(file => file.Topic === 'IT'))),
  )(
    removeErrorAndLoading(state, actionTypes.FETCH_DOCUMENTS_REQUEST, actionTypes.FETCH_DOCUMENTS_REQUEST),
  );
};
