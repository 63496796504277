import * as R from 'ramda';


export const getApiError = (
  action,
  payload,
) => R.path(['error'], action) && (R.path(['response', 'error'], payload) || R.path(['message'], payload));


const isMatch = (id, action, model) => R.equals(model.id, id) && R.equals(model.action, action);


export const removeLoading = (state, id, actionType) => R.assoc(
  'loading',
  state.loading.filter(loading => !isMatch(id, actionType, loading)),
  state,
);


export const removeError = (state, id, actionType) => R.assoc(
  'error',
  state.error.filter(error => !isMatch(id, actionType, error)),
  state,
);


export const removeErrorAndLoading = (state, id, actionType) => removeLoading(
  removeError(state, id, actionType),
  id,
  actionType,
);


export const addLoading = (state, id, actionType, meta = undefined) => R.assoc(
  'loading',
  state.loading.concat({id: id, action: actionType, meta: meta}),
  removeError(state, id, actionType),
);


export const addError = (state, id, actionType, message, meta = undefined) => R.assoc(
  'error',
  state.error.concat({id: id, action: actionType, message: message, meta: meta}),
  removeLoading(state, id, actionType),
);


export const getLoading = (state, id, actionType) => R.find(loading => isMatch(id, actionType, loading), state.loading);


export const getError = (state, id, actionType) => R.find(error => isMatch(id, actionType, error), state.error);

