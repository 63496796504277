import React from 'react';
import PropTypes from 'prop-types';
import './articlelistitem.css';
import ActionsBar from '../ActionsBar/ActionsBar';
import {DELETE_ACTION, EDIT_ACTION} from '../../constants/uiActionTypes';
import {DateDisplay} from '../DateDisplay/DateDisplay';
import {categoryTypes} from '../../constants/categoryTypes';

const makeActions = (article, onEdit, onDelete) => (
  <ActionsBar
    actions={[
      {name: EDIT_ACTION, onClick: () => onEdit(article)},
      {name: DELETE_ACTION, onClick: () => onDelete(article)},
    ]}
    buttonClassName={'btn-sm'}/>
);


const makeBody = ({titleImage, title, subtitle, body}) => (
  <div className="border-top p-2 article-body">
    {!!titleImage && <img className="img-thumbnail shadow-sm mr-2 article-image"
                          src={titleImage}
                          alt={`${title} Header`}/>}
    {!!subtitle && <p className="lead">
      {subtitle}
    </p>}
    {!!body && <p>{body}</p>}
  </div>
);


const makeFooter = ({url, publishOn, published}) => (
  <div className="d-flex article-footer">
    {!!url && url && <div className="p-2 article-links">
      <a href={url}
         target="_blank" rel="noopener noreferrer">Additional Link</a>
    </div>}
    <div className="p-2 ml-auto text-nowrap article-create-date">
      <small>
      { publishOn !== undefined && <DateDisplay value={publishOn} published={published} />}
      </small>
    </div>
  </div>
);

const getCategory = (category) => {
  var matchingCategory = categoryTypes.find(cat => cat.value === category)
  if (matchingCategory)
    return matchingCategory;

  var generalCategory = categoryTypes.find(cat => cat.value === "general")
  if (generalCategory)
    return generalCategory;
   
  return { display: "", icon: "" };
}

export const CategoryImage = (
  {
    category
  },
) => {
      var categoryType = getCategory(category);
      return (
        <img className="category-image" src={categoryType.icon} alt={categoryType.display + " icon"} title={categoryType.display} />
      );
}

export const ArticleListItem = (
  {
    article,
    canEdit,
    onEdit,
    onDelete,
    style = {},
  },
) => (
  <div className='row border rounded shadow article' style={style}>
    <div className="d-flex p-2 article-header">
      <h4 className="mb-0 mr-auto article-title">
        <CategoryImage category={article.category} />
        {article.title}
      </h4>
      {canEdit && makeActions(article, onEdit, onDelete)}
    </div>
    {makeBody(article)}
    {makeFooter(article)}
  </div>
);


ArticleListItem.propTypes = {
  article:  PropTypes.object.isRequired,
  canEdit:  PropTypes.bool,
  onEdit:   PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};


ArticleListItem.defaultProps = {
  canEdit: false,
};
