import * as R from 'ramda';
import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import kodiakLogo from "../../assets/kodiakicon.png";
import { ARTICLES_LIST, HOME, DOCUMENTS, SIGN_OUT, HELP, ANALYTICS } from '../../constants/routes';
import "./navigation.css";


const navItems = [
  {
    route: ARTICLES_LIST,
    text:  "Articles",
  },
  {
    route: DOCUMENTS,
    text:  "Documents"
  },
  {
    route: ANALYTICS,
    text:  "Analytics"
  },
  {
    route: HELP,
    text:  "Help"
  },
  {
    route: SIGN_OUT,
    text:  "Sign Out"
  },
];


const makeNavLink = (route, text, isActive) => (
  <li key={text} className="nav-item">
    <Link to={route}
          className={`nav-link ${isActive ? "active" : ""}`}>
      {text}
      {isActive && <span className="sr-only">(current)</span>}
    </Link>
  </li>
);


export default connect(
  (state, prev) => R.merge(prev, state.login),
)(
  props => (
    <nav className="navbar navbar-expand-lg navbar-light bordershadow">
      <Link to={HOME}
            className="navbar-brand">
        <img alt="Logo"
             src={kodiakLogo}
             style={{width: "50px", height: "50px"}}/>
      </Link>
      <button className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"/>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav">
          {
            navItems
              .filter(item => props.isSignedIn || item.route !== SIGN_OUT)
              .map(item => makeNavLink(item.route, item.text, props.location.pathname.endsWith(item.route)))
          }
        </ul>
      </div>
    </nav>
  )
);
