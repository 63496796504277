import * as React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import {isNilOrEmpty} from '../core/language';
import {createOption} from '../components/forms/DropDownList/DropDownList';


const ILLEGAL_NAME_STRS = ['/', '%', '\''];
const ILLEGAL_PATH_STRS = ['//', '%', '\''];

const ILLEGAL_NAME_MSG = '[ /, %, \' ]';
const ILLEGAL_PATH_MSG = '[ //, %, \' ]';


export default class UpdateFolderView extends React.PureComponent {
  static propTypes = {
    it:       PropTypes.object.isRequired,
    hr:       PropTypes.object.isRequired,
    safety:   PropTypes.object.isRequired,
    general:  PropTypes.object.isRequired,
    error:    PropTypes.string,
    folder:   PropTypes.shape(
      {
        name:  PropTypes.string,
        topic: PropTypes.string,
        path:  PropTypes.string,
      },
    ),
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
  };


  constructor(props) {
    super(props);

    this.state = {
      name:  props.folder.name,
      topic: props.folder.topic,
      path:  this.normalizePath(props.folder.path, props.folder.topic),
      error: props.error,
    };
  }


  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.error !== nextProps.error) {
      this.setState(R.assoc('error', nextProps.error));
    }

    if (!R.equals(this.props.folder, nextProps.folder)) {
      this.setState(prev => R.merge(
        prev,
        {
          name:  nextProps.folder.name,
          topic: nextProps.folder.topic,
          path:  this.normalizePath(nextProps.folder.path, nextProps.folder.topic),
        },
      ));
    }
  }


  setModelValue = (event, prop) => {
    let newValue = event.target.value;

    if (R.isNil(newValue)) {
      return;
    }

    if (typeof newValue === 'string') {
      newValue = newValue.replace('  ', ' ');
    }

    this.setState(R.assoc(prop, newValue));
  };


  onNameChange  = event => this.setModelValue(event, 'name');
  onTopicChange = event => this.setModelValue(event, 'topic');
  onPathChange  = event => this.setModelValue(event, 'path');


  onSubmit = (event) => {
    event.preventDefault(); // Don't let the form submission redirect us

    // Do validation 'n stuff
    const error = this.validateFolder(this.state.name, this.state.topic, this.state.path);
    this.setState(R.assoc('error', error));

    if (error) {
      return;
    }

    this.props.onSubmit(
      {
        oldPath:  this.normalizePath(this.props.folder.path, this.props.folder.topic) + `/${this.props.folder.name}`,
        newPath:  this.normalizePath(this.state.path.trim(), this.state.topic) + `/${this.state.name}`,
        oldTopic: this.props.folder.topic,
        newTopic: this.state.topic.trim(),
      },
    );
  };


  onCancel = (event) => {
    event.preventDefault(); // Don't let the form submission redirect us
    this.props.onCancel();
  };


  /**
   * Removes topic from path, filters out empty path segments, converts arrays to strings
   */
  normalizePath = (path, topic) => {
    if (typeof path === 'string') {
      path = path.split('/');
    }

    return R.intersperse(
      '/',
      path.filter(segment => !isNilOrEmpty(segment) && segment !== topic),
    ).reduce((running, next) => running + next, '');
  };


  validateFolder = (name, topic, path) => {
    if (isNilOrEmpty(name)) {
      return 'The folder must have a name.';
    }

    if (R.any(char => R.contains(char, name), ILLEGAL_NAME_STRS)) {
      return `Folder name cannot contain ${ILLEGAL_NAME_MSG}.`;
    }

    if (isNilOrEmpty(topic) || !R.contains(topic, ['IT', 'HR', 'Safety', 'General'])) {
      return 'The folder must have a topic.';
    }

    if (R.any(char => R.contains(char, path), ILLEGAL_PATH_STRS)) {
      return `Folder name cannot contain ${ILLEGAL_PATH_MSG}.`;
    }

    return undefined;
  };


  makeShortFileNamePath = (name) => {
    if (isNilOrEmpty(name)) {
      return '';
    }

    if (name.length <= 13) {
      return `/${name}`;
    }

    return `/${R.take(7, name) + '...' + R.takeLast(6, name)}/`;
  };


  render = () => (
    <div style={{
      position:        'fixed',
      zIndex:          100,
      width:           '100vw',
      height:          '100vh',
      top:             0,
      left:            0,
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      backgroundColor: '#000000AA',
    }}>
      <div style={{
        width:           '600px',
        margin:          'auto',
        padding:         '20px',
        backgroundColor: 'white',
      }}>
        <form>
          <div style={{
            display:       'flex',
            flexDirection: 'column',
          }}>
            {/* Form Title */}
            <h2>Update Folder</h2>

            {/* Name */}
            <div className="form-group" style={{display: 'flex', flexDirection: 'column'}}>
              <label htmlFor='folderNameInput'>Name</label>
              <input id='folderNameInput'
                     type='text'
                     autoFocus={true}
                     className="form-control"
                     value={this.state.name}
                     placeholder={'Your Folder\'s Name'}
                     onChange={this.onNameChange}
                     maxLength={50}
                     title="The name of your folder"/>
            </div>


            {/* Topic Select */}
            <div className="form-group" style={{display: 'flex', flexDirection: 'column'}}>
              <label htmlFor='topicSelect'>Category</label>
              <select id='topicSelect'
                      className="custom-select"
                      onChange={this.onTopicChange}
                      title='What kind of folder is this?'>
                {
                  [
                    {value: 'IT', display: 'IT'},
                    {value: 'HR', display: 'HR'},
                    {value: 'Safety', display: 'Safety'},
                    {value: 'General', display: 'General'},
                  ].map(createOption(this.state.topic))
                }
              </select>
            </div>

            {/* Folder name */}
            <div className="form-group" style={{display: 'flex', flexDirection: 'column'}}>
              <label htmlFor='pathInput'>Folder Name</label>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'baseline'}}>
                <input id='pathInput'
                       type='text'
                       className="form-control"
                       placeholder='Folder 1/Folder 2/Folder 3'
                       value={this.state.path}
                       onChange={this.onPathChange}
                       title="This folder's name in this category"/>
                <span style={{
                  whiteSpace: 'nowrap',
                  display:    'inline-block',
                }}>{this.makeShortFileNamePath(this.state.name)}</span>
              </div>
            </div>

            { // Error
              this.state.error && <span className="alert alert-danger">{this.state.error}</span>
            }

            {/* Submit/Cancel */}
            <div style={{
              display:       'flex',
              flexDirection: 'row',
              marginTop:     '5px',
            }}>
              <button type='submit'
                      onClick={this.onSubmit}
                      className='btn btn-primary'>
                Submit
              </button>
              <button type='cancel'
                      onClick={this.onCancel}
                      className='btn btn-secondary'
                      style={{marginLeft: '10px'}}>
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
