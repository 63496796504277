import React from 'react';
import Navigation from '../Navigation/Navigation';

const HelpPage = (props) => {
  return (
    <div>
      <Navigation location={props.location}/>
      <p>This is the support page.</p>
    </div>
  );
};

export default HelpPage;
