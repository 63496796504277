import PropTypes from 'prop-types';
import React from 'react';

const PageTitle = (props) => (<h1>{props.text}</h1>);

PageTitle.propTypes = {
  text: PropTypes.string.isRequired,
};

export default PageTitle;
