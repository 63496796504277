export const PRODUCTION_BASE_URL = "https://mobile.kodiakbp.com/api";
export const DEVELOPMENT_BASE_URL = "http://localhost:8000/api"; // For localhost dev

export const BASE_URL = (process.env.NODE_ENV.toLowerCase() === 'development') ? DEVELOPMENT_BASE_URL : PRODUCTION_BASE_URL;

/**
 * POST here for login with username/password as JSON
 */
export const LOGIN = `${BASE_URL}/login`;

/**
 * GET from or POST to here for article CRUD
 */
export const ARTICLES = `${BASE_URL}/articles`;

/**
 * GET from this endpoint to get the complete list of articles, including unpublished
 */
export const ADMIN_ARTICLES = `${BASE_URL}/articles_all`;

/**
 * GET from this endpoint to get all analytics of user interactions
 */
export const ANALYTICS = `${BASE_URL}/analytics`;

/**
 * GET to get documents
 */
export const DOCUMENTS = `${BASE_URL}/documents`;

/**
 * POST to insert/update a document
 */
export const UPSERT_DOC = `${BASE_URL}/upsertdoc`;

/**
 * DELETE to delete a document
 */
export const DELETE_DOC = `${BASE_URL}/deletedoc`;

/**
 * GET to get an entire folder, its sub-folders, and all files contained therein
 */
export const FOLDER = `${BASE_URL}/folder`;

/**
 * POST to update an entire folder, its sub-folders, and all files contained therein
 */
export const UPDATE_FOLDER = `${BASE_URL}/updatefolder`;

/**
 * POST to delete an entire folder, its sub-folders, and all files contained therein
 */
export const DELETE_FOLDER = `${BASE_URL}/deletefolder`;
