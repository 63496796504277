import React from 'react';
import './carousel.css';

const Carousel = () => {
	return(
		<div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel" data-interval='false'>
		  <ol className="carousel-indicators">
		    <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
		    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
		    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
		  </ol>
		  <div className="carousel-inner">
		    <div className="carousel-item active">
		      <img className="d-block w-100" src={require('./dog1.png') } alt="First slide" />
		    	<div className="carousel-caption d-none d-md-block">
				    <h3>Introducing the Kodiak Mobile App.</h3>
				    <p>The days of white paper announcements and physical newsletters are over! As a Kodiak Building Partners employee, enjoy access to everything right at the tips of your fingers.</p>
			  	</div>
		    </div>
		    <div className="carousel-item">
		      <img className="d-block w-100" src={require('./dog2.png') } alt="Second slide" />
		    	<div className="carousel-caption d-none d-md-block">
				    <h3>Second slide label</h3>
				    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
			  	</div>
		    </div>
		    <div className="carousel-item">
		      <img className="d-block w-100" src={require('./dog3.png') } alt="Third slide" />
		    	<div className="carousel-caption d-none d-md-block">
				    <h3>Third slide label</h3>
				    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
			  	</div>
		    </div>
		  </div>
		  <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
		    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
		    <span className="sr-only">Previous</span>
		  </a>
		  <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
		    <span className="carousel-control-next-icon" aria-hidden="true"></span>
		    <span className="sr-only">Next</span>
		  </a>
		</div>
	);
}

export default Carousel;