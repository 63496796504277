import React, {Component} from "react";
import Navigation from "../Navigation/Navigation";
import Carousel from "./Carousel/Carousel";

class LandingPage extends Component {
  render = () => (
    <div>
      <Navigation location={this.props.location}/>
      <Carousel/>
    </div>
  );
}

export default LandingPage;
