export const articleContentTypes = [
    {
         value: null
         , display: "Select Display Type"
         , displayType: ''
    }
    , {
        value: "announcement"
        , display: "Quick Announcement"
        , displayType: 'content'
    }
    , {
        value: "picture"
        , display: "Image Announcement"
        , displayType: 'image'
        , hasImage: true
    }
    , {
        value: "announcementlink"
        , display: "Quick Announcement with Link"
        , displayType: 'external'
        , hasLink: true
    }
    , {
        value: "picturelink"
        , display: "Image Announcement with Link"
        , displayType: 'external'
        , hasImage: true
        , hasLink: true
    }
];
