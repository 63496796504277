import * as React from 'react';
import * as Cookies from 'js-cookie';
import {AUTH} from './keys';
import {connect} from 'react-redux';
import * as R from 'ramda';


export default connect(
  (state, props) => R.merge(props, {
    auth: state.login,
  }),
)(class CookieMapper extends React.PureComponent {
  render() {
    Cookies.set(AUTH, this.props.auth || {});

    return null;
  }
});
