import React from 'react';
import {URL_INPUT_TYPE} from '../../constants/formTextInputTypes';
import {DropDownList} from './DropDownList/DropDownList';
import {TextInput} from './TextInput/TextInput';


export const makeDropdown = (
  selectedValue,
  label,
  elementId,
  options,
  onChange,
  title     = '',
  className = '',
  required  = false,
) => <div className={className}>
  <DropDownList label={label}
                id={elementId}
                options={options}
                selectedValue={selectedValue}
                title={title}
                required={required}
                onChange={onChange}/>
</div>;


export const makeTextAreaInput = (
  selectedValue,
  label,
  elementId,
  onChange,
  title     = '',
  className = '',
  placeholder = '',
) => <div className={className}>
  <TextInput label={label}
             onChange={onChange}
             id={elementId}
             value={selectedValue}
             title={title}
             multiline={true}
             placeholder={placeholder} />
</div>;


export const makeUrlInput = (
  selectedValue,
  label,
  elementId,
  placeholder,
  onChange,
  validationErrorMessage,
  title     = '',
  className = '',
) => {
  return (<div className={className}>
    <TextInput label={label}
               id={elementId}
               placeholder={placeholder}
               value={selectedValue}
               type={URL_INPUT_TYPE}
               title={title}
               onChange={onChange}
               validationErrorMessage={validationErrorMessage}/>
  </div>);
};


export const makeTextInput = (
  selectedValue,
  label,
  elementId,
  placeholder,
  onChange,
  title     = '',
  className = '',
  required  = false,
) => <div className={className}>
  <TextInput label={label}
             id={elementId}
             placeholder={placeholder}
             value={selectedValue}
             title={title}
             onChange={onChange}
             required={required}/>
</div>;
