import PropTypes from 'prop-types';
import React from 'react';
import {PASSWORD_TYPE, TEXT_INPUT_TYPE, URL_INPUT_TYPE} from '../../../constants/formTextInputTypes';

export const TextInput = (
  {
    id,
    label,
    multiline,
    value,
    placeholder,
    onChange,
    validationErrorMessage,
    type,
    required,
    title,
    showLabel,
    autoFocus,
    maxLength,
  },
) => <div className="form-group">
  {
    showLabel && <label htmlFor={id}> {label} </label>
  }
  {
    multiline && <textarea id={id}
                           className="form-control"
                           rows="5"
                           placeholder={placeholder}
                           onChange={onChange}
                           required={required}
                           title={title}
                           autoFocus={autoFocus}
                           value={value || ''}
                           />
  }
  {
    !multiline && <input id={id}
                         type={type}
                         className="form-control"
                         placeholder={placeholder}
                         onChange={onChange}
                         value={value || ''}
                         required={required}
                         title={title}
                         autoFocus={autoFocus}
                         maxLength={maxLength}/>
  }
  {
    validationErrorMessage && <div className="invalid-feedback">{validationErrorMessage}</div>
  }
</div>;


TextInput.propTypes = {
  id:                     PropTypes.string.isRequired,
  label:                  PropTypes.string.isRequired,
  multiline:              PropTypes.bool,
  value:                  PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder:            PropTypes.string,
  onChange:               PropTypes.func,
  validationErrorMessage: PropTypes.string,
  type:                   PropTypes.oneOf([TEXT_INPUT_TYPE, URL_INPUT_TYPE, PASSWORD_TYPE]),
  required:               PropTypes.bool,
  title:                  PropTypes.string,
  showLabel:              PropTypes.bool,
  autoFocus:              PropTypes.bool,
  maxLength:              PropTypes.number,
};


TextInput.defaultProps = {
  multiline:              false,
  placeholder:            '',
  value:                  null,
  onChange:               () => {
    /* Noop by default */
  },
  validationErrorMessage: '',
  type:                   TEXT_INPUT_TYPE,
  required:               false,
  title:                  '',
  showLabel:              true,
  autoFocus:              false,
  maxLength:              100,
};
