import * as R from 'ramda';
import actionTypes from '../appState/actionTypes';


const initialState = {
  modalType:  null,
  modalProps: {},
};


export default (state, action) => {
  switch (action.type) {
    case actionTypes.SHOW_MODAL:
      return R.mergeDeepRight(
        state,
        {
          modal: {
            modalProps: action.modalProps,
            modalType:  action.modalType,
          },
        },
      );

    case actionTypes.HIDE_MODAL:
      return R.mergeDeepRight(
        state,
        {
          modal: initialState,
        },
      );

    default:
      return state;
  }
}
