import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {Route} from 'react-router';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import {ROOT} from './constants/routes';
import './index.css';
import registerServiceWorker from './registerServiceWorker';

import 'react-datepicker/dist/react-datepicker.css';
import store from './configureStore';


ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Route path={ROOT} component={App}/>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

// Service worker to update cached static assets in the background
registerServiceWorker();
