import './articleEdit.css';

import * as R from 'ramda';
import React from 'react';
import {connect} from 'react-redux';
import {Prompt, Redirect} from 'react-router';
import {createArticle, resetArticle, updateArticle, fetchOneArticle} from '../../actions/articleActions';
import {categoryTypes} from '../../constants/categoryTypes';
import {articleContentTypes} from '../../constants/displayTypes';
import {ARTICLES_LIST} from '../../constants/routes';
import {ADMIN_ROLE, EDITOR_ROLE} from '../../constants/workerRoles';
import {ErrorMessage} from '../ErrorMessage/ErrorMessage';
import {makeDropdown, makeTextAreaInput, makeTextInput, makeUrlInput} from '../forms/factory';
import Navigation from '../Navigation/Navigation';
import PageTitle from '../PageTitle/PageTitle';
import {UnauthorizedEditorMessage} from '../ValidationMessages/UnauthorizedEditorMessage';
import DatePicker from 'react-datepicker';
import {Link} from 'react-router-dom';
import {isNilOrEmpty} from '../../core/language';
import ImageInput from '../forms/ImageInput/ImageInput';
import { format, parseISO } from 'date-fns';

const VERBOSE_DATE_FORMAT = "MMMM d, yyyy h:mm aa";

const reformatDate = (givenDate) => {
  if (givenDate === null || givenDate === undefined) return undefined;
  if (typeof(givenDate) === "string") return new Date(givenDate);
  var finalDate = parseISO(givenDate);
  return finalDate;
};

const getArticleFromStateByParam = (state, props) =>
    (!!R.path(['match', 'params', 'articleId'], props)
        && !!R.path(['articleList', 'items', 'find'], state)
        && state.articleList.items.find( a => a.id === parseInt(props.match.params.articleId) )
    );

export default connect(
  (state, props) => {
    let {updateSuccess, errorMessage, item: article} = state.articleData || {
      updateSuccess: false,
      errorMessage:  '',
      item:          {},
    };

    if (!article) {

      article = getArticleFromStateByParam(state, props);

    }

    return R.merge(
      props,
      {
        canEdit:    R.contains(state.login.workerRole, [ADMIN_ROLE, EDITOR_ROLE]),
        isSignedIn: state.login.isSignedIn,
        article,
        updateSuccess,
        errorMessage,
      },
    );
  },
  {
    createArticle,
    updateArticle,
    resetArticle,
    fetchOneArticle,
  },
)(
  class ArticleEdit extends React.PureComponent {
    constructor(props) {
      super(props);

      this.state = {
        data:         props.article || { },
        isSubmitting: false,
        error:        undefined,
      };
    }

    componentDidUpdate = (prevProps) => {
      if (this.props.article !== prevProps.article) {
        this.setState({data: this.props.article});
      }
    }

    updateState = (key, value) => this.setState(R.assoc(key, value));


    updateModel = (modelKey, modelValue) => this.updateState(
      'data',
      R.assocPath([modelKey], modelValue, this.state.data),
    );

    updateModelMultiple = (modelMap) => this.updateState(
      'data',
      R.merge(this.state.data, modelMap),
    );

    /**
     * Creates an event handler that updates the component state for the given property name. Pass the result to input
     * components for change handlers.
     */
    onChange = (accessor) => event => {
      const value = R.pathOr(event, ['target', 'value'], event);
      this.updateModel(accessor, value);
      this.setState(R.assoc('error', undefined));
    };

    onChangeArticleType = event => {
      const value = R.path(['target', 'value'], event) || event;
      const articleContentType = value;
      const displayType = this.getArticleContentType(articleContentType).displayType;
      this.updateModelMultiple({ displayType, articleContentType });
      this.setState(R.assoc('error', undefined));
    }

    getArticleContentType = articleContentType => 
        (articleContentTypes.find(act => act.value === articleContentType))
        || {}
        ;

    onChangeDate = (accessor) => newDate => {
      debugger;
      const unformattedValue = R.path(['target', 'value'], newDate) || newDate;
      const value = format(unformattedValue, VERBOSE_DATE_FORMAT);
      this.updateModel(accessor, value);
      this.setState(R.assoc('error', undefined));
    };

    onSubmit = event => {
      event.preventDefault();

      const error = this.validateForm();

      this.setState(R.assoc('error', error));

      if (error) {
        return;
      }

      this.updateState('isSubmitting', true);

      if (this.props.isEdit) {
        this.props.updateArticle(this.state.data);

      } else {
        this.props.createArticle(this.state.data || {});
      }
    };


    validateForm = () => {
      if (isNilOrEmpty(this.state.data.category)) {
        return 'A category is required';
      }

      if (isNilOrEmpty(this.state.data.displayType)) {
        return 'A display type is required';
      }

      if (isNilOrEmpty(this.state.data.title)) {
        return 'A title is required';
      }

      if (isNilOrEmpty(this.state.data.subtitle)) {
        return 'Announcement text is required';
      }

      const articleContentType = this.getArticleContentType(this.state.data.articleContentType);

      if (articleContentType.hasLink && isNilOrEmpty(this.state.data.url)) {
        return 'A URL is required';
      }

      if (articleContentType.hasImage && isNilOrEmpty(this.state.data.titleImage)
          && isNilOrEmpty(this.state.data.titleImageImg)) {
        return 'An image is required';
      }

      return undefined;
    };


    makeCategoryList = (selectedCategory) => makeDropdown(
      selectedCategory,
      'Category',
      'category-selection',
      categoryTypes,
      this.onChange('category'),
      'What kind of article is this?',
      '',
    );


    makeDisplayTypeList = (selectedDisplayType) => makeDropdown(
      selectedDisplayType,
      'Display Type',
      'display-type-selection',
      articleContentTypes,
      this.onChangeArticleType,
      'What kind of content will this article have?',
      '',
    );

    makeTitleInput = (title) => makeTextInput(
      title,
      'Announcement Title',
      'article-title-input',
      'ex. Safety Tidbit from Carlos',
      this.onChange('title'),
      '',
      'col-md-12',
    );


    makeAnouncementTextInput = (subTitle) => makeTextAreaInput(
      subTitle,
      'Announcement Text',
      'article-subtitle-input',
      this.onChange('subtitle'),
      'The text of the article itself',
      'col-md-12',
      'ex: Headlights - turn \'em on! Get in the habit of turning on your headlights, when you turn on your windshield '
      + 'wipers for driving in the rain. This will help make your vehicle more visible to others.',
    );


    makeAdditionalLinkInput = (url) => makeUrlInput(
      url,
      'Hyperlink',
      'article-additional-link-input',
      '',
      this.onChange('url'),
      'Please enter a valid URL.',
      'An optional internet address to send the user to when they open the article',
      'col-md-12',
    );


    isValidImageUrl = (url) => url !== undefined && url !== "";

    makeEditForm = (props, state, error) => (
      <form className="needs-validation shadow"
            style={{
              backgroundColor: '#E3E3E3',
              padding:         '10px',
              marginBottom:    '20px',
            }}
            onSubmit={this.onSubmit}>
        <div style={{
          display:        'flex',
          flexDirection:  'row',
          justifyContent: 'space-between',
        }}>
          {this.makeDisplayTypeList(state.articleContentType)}
          {this.makeCategoryList(state.category)}
          <div className="form-group" style={{
            display:       'flex',
            flexDirection: 'column',
          }}>
            <label htmlFor='publishOnSelect'>Publish On</label>
            <DatePicker id='publishOnSelect'
                        disabled={!!state.published}
                        selected={
                          reformatDate(state.publishOn)
                          || reformatDate(state.createdOn)
                          || new Date()
                        }
                        autoComplete={'off'}
                        minDate={state.published ? undefined : new Date()}
                        shouldCloseOnSelect={true}
                        onChange={this.onChangeDate('publishOn')}
                        showTimeSelect
                        timeFormat="h:mm a"
                        dateFormat={VERBOSE_DATE_FORMAT}
                        timeIntervals={60}
                        todayButton='Today'
                        timeCaption="Time"/>
          </div>
          <div className="form-group" style={{
            display:       'flex',
            flexDirection: 'column',
          }}>
            <label htmlFor='expireOnSelect'>Expire On</label>
            <DatePicker id='expireOnSelect'
                        selected={
                          reformatDate(state.expireOn)
                        }
                        autoComplete={'off'}
                        minDate={new Date()}
                        shouldCloseOnSelect={true}
                        onChange={this.onChangeDate('expireOn')}
                        showTimeSelect
                        timeFormat="h:mm a"
                        dateFormat={VERBOSE_DATE_FORMAT}
                        timeIntervals={60}
                        todayButton='Today'
                        timeCaption="Time"/>
          </div>
        </div>
        <div className="row">{this.makeTitleInput(state.title)}</div>
        <div className="row">{this.makeAnouncementTextInput(state.subtitle)}</div>
        {
              this.getArticleContentType(state.articleContentType).hasImage
              &&
              <ImageInput label='Announcement Image'
                          onImgChange={this.onChange('titleImageImg')}
                          onLinkChange={this.onChange('titleImage')}
                          className='mb-3'
                          select={this.isValidImageUrl(state.titleImage) && 'web'}
                          webUrl={state.titleImage}
                          />
        }
        {
              this.getArticleContentType(state.articleContentType).hasLink
              &&
              <div className="row">{this.makeAdditionalLinkInput(state.url)}</div>
        }
        {
          !isNilOrEmpty(error) && <ErrorMessage message={error}/>
        }
        <div className="d-flex justify-content-end actions">
          <Link to={ARTICLES_LIST}>
            <button type="cancel" className="btn btn-secondary mr-2">Cancel</button>
          </Link>
          <button type="submit" className="btn btn-primary">Publish</button>
        </div>
      </form>
    );


    getRenderContent = (props, state) => {

      if (props.updateSuccess) {
        return <Redirect to={ARTICLES_LIST}/>;
      }

      if (!props.canEdit) {
        return <UnauthorizedEditorMessage/>;
      }

      if (props.errorMessage) {
        return <ErrorMessage message={props.errorMessage && 'Something bad happened. Please try refreshing.'}/>;
      }

      return this.makeEditForm(props.article || {}, state.data || {}, state.error);
    };

    componentDidMount() {

      if (this.props.isEdit
          && !this.props.article
          && !!R.path(['match', 'params', 'articleId'], this.props)
          ) {

            this.props.fetchOneArticle(this.props.match.params.articleId);

      }

    }

    render = () => (
      <div>
        <Navigation location={this.props.location}/>
        <div className="container article-edit" style={{marginTop: '20px'}}>
          { this.props.isEdit ?
              <PageTitle text="Edit An Existing Article"/>
              : <PageTitle text="Publish a New Article"/>
          }
          {
            this.getRenderContent(this.props, this.state)
          }
          <Prompt when={!this.state.isSubmitting && !R.equals(this.props.article, this.state.data)}
                  message="You have unsaved changes. Do you still want to leave?"/>
        </div>
      </div>
    );
  },
);
