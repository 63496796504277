import {UPSERT_DOC} from '../constants/endpoints';
import actionTypes from '../appState/actionTypes';
import {RSAA} from 'redux-api-middleware';
import {getLoading, removeErrorAndLoading, addError, addLoading, getApiError} from '../appState/apiState';
import * as R from 'ramda';


const actions = [
  actionTypes.UPSERT_DOCUMENT_REQUEST,
  actionTypes.UPSERT_DOCUMENT_RESPONSE,
  actionTypes.UPSERT_DOCUMENT_ERROR,
];


export const upsertFileAction = (file) => ({
  [RSAA]: {
    method:   'POST',
    headers:  {'content-type': 'application/json'},
    endpoint: UPSERT_DOC,
    body:     JSON.stringify(file),
    types:    [
      {
        type: actionTypes.UPSERT_DOCUMENT_REQUEST,
        meta: {file: file},
      },
      {
        type: actionTypes.UPSERT_DOCUMENT_RESPONSE,
        meta: {file: file},
      },
      {
        type: actionTypes.UPSERT_DOCUMENT_ERROR,
        meta: {file: file},
      },
    ],
    bailout:  state => getLoading(state, file, actionTypes.UPSERT_DOCUMENT_REQUEST),
  },
});


export const upsertFileReducer = (state, action) => {
  if (!actions.includes(action.type)) {
    return state;
  }

  const file      = R.path(['meta', 'file'], action);
  const payload   = R.path(['payload'], action);
  const error     = getApiError(action, payload);
  const isLoading = action.type === actionTypes.UPSERT_DOCUMENT_REQUEST && !error;

  if (isLoading) {
    return addLoading(state, file, actionTypes.UPSERT_DOCUMENT_REQUEST);
  }

  if (error) {
    return addError(state, file, actionTypes.UPSERT_DOCUMENT_REQUEST, `Failed to contact server: ${error}`);
  }

  // TODO: Upsert the file in app state
  return removeErrorAndLoading(state, file, actionTypes.UPSERT_DOCUMENT_REQUEST);
};
