import PropTypes from 'prop-types';
import React from 'react';
import './dropdownlist.css';

export const createOption = option => {
  const {value, display} = option;

  return (
    <option value={value}
            key={value}>
      {display}
    </option>
  );
};

export const DropDownList = (
  {
    id,
    label,
    options,
    onChange,
    selectedValue,
    required,
    title,
  },
) => <div className="form-group">
  <label htmlFor={id}>
    {label}
  </label>
  <select id={id}
          className="custom-select"
          onChange={onChange}
          required={required}
          title={title}
          value={selectedValue === null ? "" : selectedValue}>
    {options.map(createOption)}
  </select>
</div>;

DropDownList.propTypes = {
  id:            PropTypes.string.isRequired,
  label:         PropTypes.string.isRequired,
  options:       PropTypes.arrayOf(
    PropTypes.shape(
      {
        value:   PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        display: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })).isRequired,
  onChange:      PropTypes.func,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required:      PropTypes.bool,
};

DropDownList.defaultProps = {
  onChange:      () => { /* Noop by default */ },
  selectedValue: null,
  required:      false,
};
