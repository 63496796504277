import {RSAA} from 'redux-api-middleware';
import {LOGIN} from '../constants/endpoints';
import actionTypes from '../appState/actionTypes';

/**
 * Constructs an action that can be dispatched to the Redux Store to attempt a login
 */
export const makeLoginAction = (lastName, ssn) => ({
  [RSAA]: {
    method:   'POST',
    headers:  {
      'content-type': 'application/json',
    },
    endpoint: LOGIN,
    body:     JSON.stringify(
      {
        lastname: lastName,
        ssn,
      },
    ),
    types:    [
      {
        type: actionTypes.LOGIN_REQUEST,
        meta: {
          lastName: lastName,
          ssn:      ssn,
        },
      },
      {
        type: actionTypes.LOGIN_RESPONSE,
        meta: {
          lastName: lastName,
          ssn:      ssn,
        },
      },
      {
        type: actionTypes.LOGIN_ERROR,
        meta: {
          lastName: lastName,
          ssn:      ssn,
        },
      },
    ],
    bailout:  (appState) => appState.login.isSigningIn,
  },
});
