import keyMirror from 'keymirror';

const actionTypes = keyMirror(
  {
    // Login actions
    LOGIN_REQUEST:  null,
    LOGIN_RESPONSE: null,
    LOGIN_ERROR:    null,

    // Logout actions
    LOGOUT_REQUEST: null,

    // article list actions
    ARTICLE_LIST_REQUEST:     null,
    ARTICLE_LIST_RESPONSE:    null,
    ARTICLE_LIST_ERROR:       null,
    ARTICLE_LIST_CHANGE_PAGE: null,

    // single article get actions
    ARTICLE_GET_REQUEST:      null,
    ARTICLE_GET_RESPONSE:     null,
    ARTICLE_GET_ERROR:        null,

    // edit article actions
    SET_ARTICLE_TO_EDIT: null,
    RESET_EDIT_ARTICLE:  null,

    // article API update actions
    ARTICLE_UPDATE_REQUEST:  null,
    ARTICLE_UPDATE_RESPONSE: null,
    ARTICLE_UPDATE_ERROR:    null,

    // article API create actions
    ARTICLE_CREATE_REQUEST:  null,
    ARTICLE_CREATE_RESPONSE: null,
    ARTICLE_CREATE_ERROR:    null,

    // article API delete actions
    ARTICLE_DELETE_REQUEST:  null,
    ARTICLE_DELETE_RESPONSE: null,
    ARTICLE_DELETE_ERROR:    null,
    
    // single article get actions
    ANALYTICS_GET_REQUEST:  null,
    ANALYTICS_GET_RESPONSE: null,
    ANALYTICS_GET_ERROR:    null,
    ANALYTICS_CLEAR_EVENTS: null,

    // category type retrieval
    CATEGORY_TYPE_RETRIEVE_REQUEST:  null,
    CATEGORY_TYPE_RETRIEVE_RESPONSE: null,
    CATEGORY_TYPE_RETRIEVE_ERROR:    null,

    // modal actions
    SHOW_MODAL: null,
    HIDE_MODAL: null,

    // search actions
    ENTER_SEARCH: null,

    // Document Actions
    FETCH_DOCUMENTS_REQUEST:  null,
    FETCH_DOCUMENTS_RESPONSE: null,
    FETCH_DOCUMENTS_ERROR:    null,

    DELETE_DOCUMENT_REQUEST:  null,
    DELETE_DOCUMENT_RESPONSE: null,
    DELETE_DOCUMENT_ERROR:    null,

    UPSERT_DOCUMENT_REQUEST:  null,
    UPSERT_DOCUMENT_RESPONSE: null,
    UPSERT_DOCUMENT_ERROR:    null,

    UPDATE_FOLDER_REQUEST:  null,
    UPDATE_FOLDER_RESPONSE: null,
    UPDATE_FOLDER_ERROR:    null,

    DELETE_FOLDER_REQUEST:  null,
    DELETE_FOLDER_RESPONSE: null,
    DELETE_FOLDER_ERROR:    null,
  },
);

export default actionTypes;
