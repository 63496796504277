import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import {TEXT_INPUT_TYPE} from '../../../constants/formTextInputTypes';
import {TextInput} from '../../forms/TextInput/TextInput';
import './signinmodal.css';

class SignInModal extends React.PureComponent {
  state = {
    lastName: '',
    ssn:      '',
  };

  onLastNameChange = (event) => {
    if (!event || !event.target) {
      return;
    }

    const newValue = event.target.value; // setState is async, and events are recycled... You do the math.

    this.setState(prev => R.assoc('lastName', newValue, prev));
  };

  onSsnChange = (event) => {
    if (!event || !event.target) {
      return;
    }

    const newValue = event.target.value; // setState is async, and events are recycled... You do the math.

    this.setState(prev => R.assoc('ssn', newValue, prev));
  };

  onSubmitSignIn = (event) => {
    event.preventDefault(); // Prevent form submission screwing with the current route
    this.props.onSignIn(this.state.lastName, this.state.ssn);
  };

  render() {
    const {error} = this.props;

    return (
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="m-0">
            Sign In
          </h5>
        </div>
        <div className="modal-body">
          <p>Enter your last name as it exists in ADP and the last 4 digits of your SSN.</p>
          <form>
            {!!error && <span className="error">{error}</span>}
            <TextInput label="Last Name"
                       id="sign-in-last-name"
                       type={TEXT_INPUT_TYPE}
                       maxLength={50}
                       autoFocus={true}
                       onChange={this.onLastNameChange}
                       placeholder="Last Name"
                       title="Your last name as it appears in PayCom"
                       required={true}
                       showLabel={false}
                       value={this.state.lastName}
                       />
            <TextInput label="SSN"
                       id="sign-in-ssn"
                       type='password'
                       maxLength={4}
                       onChange={this.onSsnChange}
                       placeholder="Last 4 of SSN"
                       title="The last 4 digits of your Social Security Number"
                       required={true}
                       showLabel={false}
                       value={this.state.ssn}
                       />
            <button className="btn w-100 sign-in-button"
                    type="submit"
                    onClick={this.onSubmitSignIn}>Sign In
            </button>
          </form>
        </div>
      </div>
    );
  }
}

SignInModal.propTypes = {
  onSignIn: PropTypes.func,
  error:    PropTypes.string,
};

export default SignInModal;
