import * as React from 'react';
import Navigation from '../Navigation/Navigation';


export const InstallPage = (props) => (
  <div style={{height: '100vh'}}>
    <Navigation location={props.location}/>
    <div style={{
      display:        'flex',
      flexDirection:  'column',
      alignItems:     'center',
      justifyContent: 'center',
      height:         '100%',
    }}>
      <h1 style={{textAlign: 'center'}}>Install the Kodiak App</h1>

      <a
        href='https://play.google.com/store/apps/details?id=com.kodiakapp&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
        <img alt='Get it on Google Play'
             src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png'
             style={{
               width:        '350px',
               height:       '135.5px',
               marginTop:    '15px',
               marginBottom: '20px',
             }}/>
      </a>

      <a href="https://itunes.apple.com/us/app/kodiak-building-partners-app/id1438057501?mt=8">
        <img alt='Download Kodiak App on the Apple App Store'
             src='https://linkmaker.itunes.apple.com/assets/shared/badges/en-us/appstore-lrg.svg'
             style={{
               width:  '306px',
               height: '90.5px',
             }}/>
      </a>
    </div>
  </div>
);

export default InstallPage;
