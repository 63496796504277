import * as R from "ramda";
import React from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router";
import {makeLogoutAction} from "../../actions/logoutActions";
import {HOME} from "../../constants/routes";


export default connect(
  (state, props) => R.merge(props, state.login),
  (dispatch, props) => R.assoc('logout', () => dispatch(makeLogoutAction()), props)
)(
  class SignOut extends React.PureComponent {
    componentDidMount() {
      this.props.logout();
    }

    render = () => this.props.isSignedIn ? null : <Redirect to={HOME}/>;
  }
);
