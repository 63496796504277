import React from "react";
import PropTypes from "prop-types";
import './modalcontainer.css';
import modalTypes from "./ModalTypes";
import modalConstants from "../../constants/modalConstants";

const MODAL_TYPES = {};
MODAL_TYPES[modalConstants.CONFIRM_MODAL] = modalTypes.confirmModal;
MODAL_TYPES[modalConstants.DELETE_MODAL] = modalTypes.deleteModal;
MODAL_TYPES[modalConstants.SIGN_IN_MODAL] = modalTypes.signInModal;

class ModalContainer extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: true
        };
        this.closeModal = this.closeModal.bind(this)
    }

    closeModal() {
        this.setState({modalIsOpen: false})
    }

    render() {
        if (!this.state.modalIsOpen) {
            return null;
        }

        const {modalType, modalProps} = this.props;
        if (!modalType || modalType === null) {
            return null;
        }

        const SpecifiedModal = MODAL_TYPES[modalType];
        return (
            <div className={`modal fade ${this.state.modalIsOpen ? "show" : ""}`} role="dialog">
                <div className="modal-dialog" role="document">
                    <SpecifiedModal
                        closeModal={this.closeModal}
                        {...modalProps}
                    />
                </div>
            </div>
        );
    }
}

ModalContainer.propTypes = {
    modalType: PropTypes.string.isRequired,
    modalProps: PropTypes.object,
};

ModalContainer.defaultProps = {
    modalProps: {},
};

export default ModalContainer;
