export const ROOT                = "";
export const ARTICLES_LIST       = ROOT + "/articles/list";
export const ARTICLES_EDIT_BASE  = ROOT + "/articles/edit";
export const ARTICLES_EDIT_ROUTE = ROOT + "/articles/edit/:articleId?";
export const ARTICLES_CREATE     = ROOT + "/articles/create";
export const ANALYTICS           = ROOT + "/analytics"
export const HELP                = ROOT + "/help";
export const DOCUMENTS           = ROOT + "/documents";
export const INSTALL             = ROOT + "/install";
export const HOME                = ROOT + "/";
export const SIGN_IN             = ROOT + "/signin";
export const SIGN_OUT            = ROOT + "/signout";

/**
 * The pages in the app that can be used without requiring the user to sign in.
 */
export const PUBLIC_PAGES = [
    INSTALL,
];
