import * as React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import FileInput from '../components/forms/FileInput/FileInput';
import {isNilOrEmpty} from '../core/language';
import {createOption} from '../components/forms/DropDownList/DropDownList';


const ILLEGAL_NAME_STRS = ['/', '%', '\''];
const ILLEGAL_PATH_STRS = ['//', '%', '\''];

const ILLEGAL_NAME_MSG = '[ /, %, \' ]';
const ILLEGAL_PATH_MSG = '[ //, %, \' ]';

const FILE_TYPES = [
                    {value: 'IT', display: 'IT'},
                    {value: 'HR', display: 'HR'},
                    {value: 'Safety', display: 'Safety'},
                    {value: 'General', display: 'General'}
];

export default class UpsertDocumentView extends React.PureComponent {
  static propTypes = {
    title:      PropTypes.string.isRequired,
    it:         PropTypes.object.isRequired,
    hr:         PropTypes.object.isRequired,
    safety:     PropTypes.object.isRequired,
    general:    PropTypes.object.isRequired,
    loading:    PropTypes.bool.isRequired,
    error:      PropTypes.string,
    uploadPath: PropTypes.string,
    file:       PropTypes.shape(
      {
        fileName: PropTypes.string,
        topic:    PropTypes.string,
        path:     PropTypes.string,
        url:      PropTypes.string,
      },
    ),
    onSubmit:   PropTypes.func,
    onCancel:   PropTypes.func,
  };

  // initialize state in case it is accessed prior to constructor
  state = {
  };

  constructor(props) {
    super(props);

    this.state = {
      fileName: R.path(['file', 'fileName'], props) || '',
      topic:    R.path(['file', 'topic'], props) || 'General',
      path:     R.path(['file', 'path'], props) || this.props.uploadPath || '',
      file:     undefined,
      error:    props.error,
    };
  }


  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.error !== nextProps.error) {
      this.setState(R.assoc('error', nextProps.error));
    }

    if (!R.equals(this.props.file, nextProps.file)) {
      this.setState(prev => R.merge(prev, nextProps.file));
    }
  }


  setModelValue = (event, prop) => {
    let newValue = event.target.value;

    if (R.isNil(newValue)) {
      return;
    }

    if (typeof newValue === 'string') {
      newValue = newValue.replace('  ', ' ');
    }

    this.setState(R.assoc(prop, newValue));
  };


  onFileNameChange = event => this.setModelValue(event, 'fileName');
  onTopicChange    = event => this.setModelValue(event, 'topic');
  onPathChange     = event => this.setModelValue(event, 'path');


  onFileChange = (changes) => {
    const fileName = changes.fileData.name;
    const fileBlob = changes.blob;

    if (isNilOrEmpty(this.state.fileName)) {
      this.setState(R.assoc('fileName', fileName.replace('.pdf', '')));
    }

    this.setState(R.assoc('file', fileBlob));
  };


  onSubmit = (event) => {
    event.preventDefault(); // Don't let the form submission redirect us

    // Do validation 'n stuff
    const error = this.validateFile(this.state.fileName, this.state.topic, this.state.path, this.state.file);
    this.setState(R.assoc('error', error));

    if (error) {
      return;
    }

    this.props.onSubmit(
      {
        Id:       R.path(['file', 'id'], this.props),
        FileName: this.state.fileName,
        Topic:    this.state.topic,
        Path:     this.state.path,
        FileBlob: this.state.file,
        Url:      R.path(['file', 'url'], this.props),
      },
    );
  };


  onCancel = (event) => {
    event.preventDefault(); // Don't let the form submission redirect us
    this.props.onCancel();
  };


  validateFile = (name, topic, path, fileObj) => {
    if (isNilOrEmpty(name)) {
      return 'The file must have a name.';
    }

    if (R.any(char => R.contains(char, name), ILLEGAL_NAME_STRS)) {
      return `File name cannot contain ${ILLEGAL_NAME_MSG}.`;
    }

    if (isNilOrEmpty(topic) || !R.contains(topic, ['IT', 'HR', 'Safety', 'General'])) {
      return 'The file must have a topic.';
    }

    if (R.any(char => R.contains(char, path), ILLEGAL_PATH_STRS)) {
      return `Folder name cannot contain ${ILLEGAL_PATH_MSG}.`;
    }

    if (R.isNil(fileObj) && !R.path(['file', 'url'], this.props)) {
      return 'You must upload a file';
    }

    return undefined;
  };


  makeShortFileNamePath = (fileName) => {
    if (isNilOrEmpty(fileName)) {
      return '';
    }

    if (fileName.length <= 10) {
      return `/${fileName}.pdf`;
    }

    return `/${R.take(5, fileName) + '...' + R.takeLast(5, fileName)}.pdf`;
  };


  render = () => (
    <div style={{
      position:        'fixed',
      zIndex:          100,
      width:           '100vw',
      height:          '100vh',
      top:             0,
      left:            0,
      display:         'flex',
      alignItems:      'center',
      justifyContent:  'center',
      backgroundColor: '#000000AA',
    }}>
      <div style={{
        width:           '600px',
        margin:          'auto',
        padding:         '20px',
        backgroundColor: 'white',
      }}>
        <form>
          <div style={{
            display:       'flex',
            flexDirection: 'column',
          }}>
            {/* Form Title */}
            <h2>{this.props.title}</h2>

            { // Existing file on server
              R.path(['file', 'url'], this.props) && <a target='_blank'
                                                        rel="noopener noreferrer"
                                                        href={this.props.file.url}
                                                        style={{
                                                          marginTop:    '10px',
                                                          marginBottom: '10px',
                                                        }}>{this.props.file.fileName}</a>
            }

            {/* File Name */}
            <div className="form-group" style={{display: 'flex', flexDirection: 'column'}}>
              <label htmlFor='fileNameInput'>Name</label>
              <input id='fileNameInput'
                     type='text'
                     autoFocus={true}
                     className="form-control"
                     value={this.state.fileName}
                     placeholder='Your File Name'
                     onChange={this.onFileNameChange}
                     maxLength={50}
                     title="The name of the PDF"/>
            </div>


            {/* Topic Select */}
            <div className="form-group" style={{display: 'flex', flexDirection: 'column'}}>
              <label htmlFor='topicSelect'>Category</label>
              <select id='topicSelect'
                      className="custom-select"
                      onChange={this.onTopicChange}
                      title='What kind of file is this?'
                      value={this.state.topic}
                      >
                { 
                  FILE_TYPES.map(createOption)
                }
              </select>
            </div>

            {/* Folder */}
            <div className="form-group" style={{display: 'flex', flexDirection: 'column'}}>
              <label htmlFor='pathInput'>Folder Name</label>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'baseline'}}>
                <input id='pathInput'
                       type='text'
                       className="form-control"
                       placeholder='Folder 1/Folder 2/Folder 3'
                       value={this.state.path}
                       onChange={this.onPathChange}
                       title="This folder's name in this category"/>
                <span style={{
                  whiteSpace: 'nowrap',
                  display:    'inline-block',
                }}>{this.makeShortFileNamePath(this.state.fileName)}</span>
              </div>
            </div>

            {/* PDF Upload */}
            <FileInput label='PDF'
                       fileTypes='.pdf'
                       value={this.state.file}
                       title='The PDF to distribute to Kodiak employees using the web and the mobile app'
                       onChange={this.onFileChange}/>

            {
              this.state.error && <span className="alert alert-danger">{this.state.error}</span>
            }

            {/* Submit/Cancel */}
            <div style={{
              display:       'flex',
              flexDirection: 'row',
              marginTop:     '5px',
            }}>
              <button type='submit'
                      onClick={this.onSubmit}
                      className='btn btn-primary'>
                Submit
              </button>
              <button type='cancel'
                      onClick={this.onCancel}
                      className='btn btn-secondary'
                      style={{
                        marginLeft: '10px',
                      }}>
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
