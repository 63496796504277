import actionTypes from "../appState/actionTypes";


export const showModal = (modalProps, modalType) => ({
    type: actionTypes.SHOW_MODAL,
    modalProps,
    modalType
});

export const hideModal = () => ({type: actionTypes.HIDE_MODAL});
