import {isNilOrEmpty} from './language';

export const prettyDate = (date) => {
  if (isNilOrEmpty(date)) {
    return '';
  }

  const d = new Date(date);

  let month  = String(d.getMonth() + 1);
  let day    = String(d.getDate());
  const year = String(d.getFullYear());
  const hours = d.getHours();
  let minutes = d.getMinutes();
  const amPm = hours / 12 > 1 ? 'pm' : 'am';

  if (month.length < 2) {
    month = '0' + month;
  }

  if (day.length < 2) {
    day = '0' + day;
  }

  if(minutes < 10) {
    minutes = '0' + minutes;
  }

  return `${month}/${day}/${year} ${hours % 12}:${minutes}${amPm}`;
};
