import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faSearch} from "@fortawesome/free-solid-svg-icons"
import "./searchinput.css";
import {enterSearch} from "../../../actions/searchActions";

class SearchInput extends React.PureComponent {
    onChange = event => {
        event.preventDefault();

        const {enterSearch, searchType} = this.props;
        const newValue = event.target.value;

        enterSearch(searchType, newValue);
    };

    render() {
        const {inputId, searchPlaceholder} = this.props;

        return <div className="input-group">
            <input className="form-control py-2 border-right-0 border"
                   type="search"
                   placeholder={searchPlaceholder}
                   id={inputId}
                   onChange={this.onChange}/>
            <span className="input-group-append">
                  <div className="input-group-text bg-transparent">
                      <FontAwesomeIcon icon={faSearch}/>
                  </div>
            </span>
        </div>;
    }
}

SearchInput.propTypes = {
    inputId: PropTypes.string.isRequired,
    searchType: PropTypes.string.isRequired,
    searchPlaceholder: PropTypes.string,
};

SearchInput.defaultProps = {
    searchPlaceholder: "Type search terms",
};

export default connect(null, {enterSearch})(SearchInput);
