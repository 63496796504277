import PropTypes from 'prop-types';
import * as R from 'ramda';
import './articleList.css';
import React from 'react';
import ReactPaginate from 'react-paginate';
import {connect} from 'react-redux';
import {changeArticlePage, deleteArticle, editArticle, fetchArticles} from '../../actions/articleActions';
import {hideModal, showModal} from '../../actions/modalActions';
import listConfig from '../../constants/listConfig';
import modalButtons from '../../constants/modalButtons';
import modalConstants from '../../constants/modalConstants';
import {ARTICLES_CREATE, ARTICLES_EDIT_BASE} from '../../constants/routes';
import searchTypes from '../../constants/searchTypes';
import {CREATE_ARTICLE_ACTION} from '../../constants/uiActionTypes';
import {ADMIN_ROLE, EDITOR_ROLE} from '../../constants/workerRoles';
import ActionsBar from '../ActionsBar/ActionsBar';
import {ArticleListItem} from '../ArticleListItem/ArticleListItem';
import {ErrorMessage} from '../ErrorMessage/ErrorMessage';
import SearchInput from '../forms/SearchInput/SearchInput';
import Navigation from '../Navigation/Navigation';
import {EmptyListMessage} from '../ValidationMessages/EmptyListMessage';
import {LoadingMessage} from '../ValidationMessages/LoadingMessage';


const getPageIndex = page => page * listConfig.ARTICLES_PER_PAGE;
const getPageCount = index => Math.ceil(index / listConfig.ARTICLES_PER_PAGE);

const matchesSearch = searchTerm => article => ['title', 'subtitle', 'body']
  .map(accessor => (article[accessor] || ''))
  .map(R.toLower)
  .map(R.trim)
  .reduce((running, next) => running || R.contains(searchTerm, next), false);


export default connect(
  (state, props) => {
    const {errorMessage, isFetching, currentPage, searchTerm, items: articles} = state.articleList || {
      errorMessage: '',
      isFetching:   true,
      items:        [],
      currentPage:  0,
      searchTerm:   '',
    };

    const {workerRole, isSignedIn} = state.login;

    const filteredArticles = R.filter(matchesSearch(searchTerm.toLocaleLowerCase()), articles);

    return R.merge(
      props,
      {
        isFetching,
        articles:  R.slice(
          getPageIndex(currentPage),
          getPageIndex(currentPage + 1),
          filteredArticles,
        ),
        canEdit:   R.contains(workerRole, [ADMIN_ROLE, EDITOR_ROLE]),
        isSignedIn,
        errorMessage,
        currentPage,
        pageCount: getPageCount(filteredArticles.length),
      },
    );
  },
  {
    fetchArticles,
    editArticle,
    deleteArticle,
    changeArticlePage,
    hideModal,
    showModal,
  },
)(
  class ArticleList extends React.PureComponent {
    static propTypes = {
      articles: PropTypes.array.isRequired,
    };


    onEdit = article => {
      this.props.editArticle(article);
      this.props.history.push(`${ARTICLES_EDIT_BASE}/${article.id}`);
    };


    onDelete = article => {
      const {showModal, deleteArticle} = this.props;

      showModal(
        {
          open:         true,
          title:        'Delete Article',
          message:      'Are you sure you want to delete this article?',
          deleteAction: () => {
            deleteArticle(article);
            this.closeModal();
          },
          closeModal:   this.closeModal,
          deleteText:   modalButtons.DELETE,
          cancelText:   modalButtons.CANCEL,
        },
        modalConstants.DELETE_MODAL,
      );
    };


    onCreate = () => {
      this.props.editArticle({});
      this.props.history.push(ARTICLES_CREATE);
    };


    onPageChange = ({selected}) => this.props.changeArticlePage(selected);


    closeModal = () => this.props.hideModal();


    createArticleDisplay = canEdit => (article) => (
      <ArticleListItem key={article.id}
                       article={article}
                       canEdit={canEdit}
                       onEdit={this.onEdit}
                       onDelete={this.onDelete}
                       style={{marginBottom: '20px'}}/>
    );


    componentDidMount() {
      this.props.fetchArticles();
    }


    render = () => (
      <div>
        <Navigation location={this.props.location}/>
        <div className="container" style={{
          marginTop: '20px',
        }}>
          { // Loading
            this.props.isFetching && <LoadingMessage/>
          }
          { // Errored
            this.props.errorMessage && <ErrorMessage message={this.props.errorMessage}/>
          }
          { // Articles
            !this.props.isFetching
            && !this.props.errorMessage
            && <div>
              <div className="header row">
                {
                  this.props.canEdit
                  && <ActionsBar actions={[{name: CREATE_ARTICLE_ACTION, onClick: this.onCreate}]}
                                 barClassName={'col-md-2 pl-0'}
                                 buttonClassName={'form-control'}/>
                }
                <div className="col-md-8 ml-auto pr-0">
                  <SearchInput inputId={'article-search'}
                               searchPlaceholder={'Search for articles'}
                               searchType={searchTypes.ARTICLE_SEARCH}/>
                </div>
              </div>
              <div className="articles" style={{marginTop: '20px'}}>
                {
                  this.props.articles.length > 0
                  ? this.props.articles.map(this.createArticleDisplay(this.props.canEdit))
                  : <EmptyListMessage/>
                }
              </div>
              <nav>
                <ReactPaginate pageCount={this.props.pageCount}
                               pageRangeDisplayed={10}
                               marginPagesDisplayed={3}
                               previousLabel={'Previous'}
                               previousClassName={'page-item'}
                               previousLinkClassName={'page-link'}
                               nextLabel={'Next'}
                               nextClassName={'page-item'}
                               nextLinkClassName={'page-link'}
                               breakLabel={'...'}
                               onPageChange={this.onPageChange}
                               initialPage={this.props.currentPage}
                               disableInitialCallback={true}
                               containerClassName={'pagination justify-content-center'}
                               pageClassName={'page-item'}
                               pageLinkClassName={'page-link'}
                               activeClassName={'active'}
                               disabledClassName={'disabled'}/>
              </nav>
            </div>
          }
        </div>
      </div>
    );
  },
);
