import PropTypes from 'prop-types';
import React from 'react';

const createAction = buttonClassName => ({name, onClick}) => (
  <button key={name}
          type="button"
          className={`btn btn-primary mr-1 ml-1 ${buttonClassName}`}
          onClick={onClick}>{name}</button>
);


const ActionsBar = (
  {
    actions,
    buttonClassName,
    barClassName,
  },
) => <div className={`actions-bar ${barClassName}`}>{actions.map(createAction(buttonClassName))}</div>;


ActionsBar.propTypes = {
  actions:         PropTypes.arrayOf(PropTypes.shape({
                                                       name:    PropTypes.string.isRequired,
                                                       onClick: PropTypes.func,
                                                     })),
  buttonClassName: PropTypes.string,
  barClassName:    PropTypes.string,
};


ActionsBar.defaultProps = {
  buttonClassName: '',
  barClassName:    '',
};


export default ActionsBar;
