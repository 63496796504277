import * as R from 'ramda';
import {isNilOrEmpty} from '../core/language';


export const cannonicalPath = (dir) => {
  const pathArray = dir.path.concat(dir.name).filter(segment => !isNilOrEmpty(segment));
  return R.intersperse('/', pathArray).reduce((running, next) => running + next, '');
};


/**
 * Makes a new directory object with the given name and path.
 */
export const makeDir = (name, path, topic) => ({
  name:     name,
  path:     path,
  topic:    topic,
  isFolder: true,
  children: [],
});


/**
 * Returns the complete path of the given directory -- this will include the directory itself
 * @returns { string[] }
 */
export const fullPath = (dir) => dir.path.concat(dir.name);


/**
 * Returns the first child dir whose name matches the given name. returns undefined if none found.
 */
export const getChildDir = (name, dir) => dir.children.find(child => child.name === name, dir.children);


/**
 * Adds a child to the given directory. Doesn't care what the child object is.
 * @param dir - the directory whose children we want to add to
 * @param child - the child to add (can be whatever)
 */
export const addChild = (dir, child) => R.assoc('children', dir.children.concat(child), dir);


/**
 * Removes all child directories of the given name from the given directory. If none exist, no problem.
 * @param dir - the directory to remove the children from
 * @param name - The name of the children to excise
 */
export const removeChildDir = (dir, name) => R.assoc(
  'children',
  dir.children.filter(child => child.name !== name),
  dir,
);


/**
 * Adds a new child directory to the given directory if one doesn't already exist.
 * @param dir - the directory to add the child to
 * @param name - the name of the directory to add if it doesn't exist
 * @param topic - the folder's topic
 * @returns new instance of the parent directory
 */
export const addDirIfNotExists = (dir, name, topic) => {
  if (!getChildDir(name, dir)) {
    return addChild(dir, makeDir(name, fullPath(dir), topic));
  }

  return dir;
};


/**
 * Adds the given file from the server to the given directory, making files where necessary, and returning a new object.
 */
export const addFile = (dir, file) => {
  const filePath = file.Path.split('/').filter(segment => !isNilOrEmpty(segment));
  // Add file to dir if they belong together
  if (dir.path.length === filePath.length) {
    return addChild(dir, file);
  }

  // Find/add the correct, immediate child for this file
  const nextDirName = filePath[dir.path.length];
  const newTarget   = getChildDir(nextDirName, addDirIfNotExists(dir, nextDirName, file.Topic));

  // Recurse into our child dir and add the file at the bottom
  const withFile = addFile(newTarget, file);

  // Replace our old child dir with the new one that has the file
  return addChild(removeChildDir(dir, nextDirName), withFile);
};


/**
 * Takes a list of files and returns a tree. Assumes the documents are of the same category.
 * returns Folder: {
 *   name:     string,
 *   path:     string[],
 *   isFolder: boolean,
 *   children: Array<Folder|File>,
 * }
 */
export const deserializeDocTree = (files) => (files || []).reduce(
  (running, next) => addFile(running, next),  // Sum each file into the hierarchy
  makeDir('', [], R.prop('Topic', files[0])), // Start with root dir
);
