import {UNAUTHORIZED_ROLE} from '../constants/workerRoles';

export const INITIAL_APP_STATE = { // Initial App State
  /**
   * shape: {
   *   id: string
   *   action: string,
   *   meta: object | undefined
   * }
   */
  loading:     [],
  /**
   * shape: {
   *   id: string
   *   action: string,
   *   message: string,
   *   meta: object | undefined
   * }
   */
  error:       [],
  login:       {
    isSignedIn:  false,
    isSigningIn: false,
    lastName:    undefined,
    ssn:         undefined,
    lastSignIn:  undefined,
    signInError: undefined,
    workerRole:  UNAUTHORIZED_ROLE,
  },
  modal:       {
    modalType:  null,
    modalProps: {},
  },
  articleList: {
    isFetching:  false,
    items:       [],
    currentPage: 0,
    searchTerm:  '',
  },
  articleData: {
    updateSuccess: false,
    item:          null,
    errorMessage:  '',
  },
  analytics: {
    isFetching: false,
    errorMessage: '',
    events: [],
  },
};

export default INITIAL_APP_STATE;
