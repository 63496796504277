import {UPDATE_FOLDER} from '../constants/endpoints';
import actionTypes from '../appState/actionTypes';
import {RSAA} from 'redux-api-middleware';
import {getLoading, removeErrorAndLoading, addError, addLoading, getApiError} from '../appState/apiState';
import * as R from 'ramda';


const actions = [
  actionTypes.UPDATE_FOLDER_REQUEST,
  actionTypes.UPDATE_FOLDER_RESPONSE,
  actionTypes.UPDATE_FOLDER_ERROR,
];


export const updateFolderAction = (oldPath, newPath, oldTopic, newTopic) => {
  const folderDiff = {
    oldPath:  oldPath,
    newPath:  newPath,
    oldTopic: oldTopic,
    newTopic: newTopic,
  };

  return ({
    [RSAA]: {
      method:   'POST',
      headers:  {'content-type': 'application/json'},
      endpoint: UPDATE_FOLDER,
      body:     JSON.stringify(folderDiff),
      types:    [
        {
          type: actionTypes.UPDATE_FOLDER_REQUEST,
          meta: {folderDiff: folderDiff},
        },
        {
          type: actionTypes.UPDATE_FOLDER_RESPONSE,
          meta: {folderDiff: folderDiff},
        },
        {
          type: actionTypes.UPDATE_FOLDER_ERROR,
          meta: {folderDiff: folderDiff},
        },
      ],
      bailout:  state => getLoading(state, folderDiff, actionTypes.UPDATE_FOLDER_REQUEST),
    },
  });
};


export const updateFolderReducer = (state, action) => {
  if (!actions.includes(action.type)) {
    return state;
  }

  const folderDiff = R.path(['meta', 'folderDiff'], action);
  const payload    = R.path(['payload'], action);
  const error      = getApiError(action, payload);
  const isLoading  = action.type === actionTypes.UPDATE_FOLDER_REQUEST && !error;

  if (isLoading) {
    return addLoading(state, folderDiff, actionTypes.UPDATE_FOLDER_REQUEST);
  }

  if (error) {
    return addError(state, folderDiff, actionTypes.UPDATE_FOLDER_REQUEST, `Failed to contact server: ${error}`);
  }

  // TODO: Upsert the file in app state
  return removeErrorAndLoading(state, folderDiff, actionTypes.UPDATE_FOLDER_REQUEST);
};
