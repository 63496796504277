import * as React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import Spinner from '../Spinner/Spinner';

/**
 * An input for accepting file uploads from the user. The value from change events will be a File[].
 *
 * The most interesting prop on here is `fileTypes`, which is a string of comma-separated `Unique File Type Specifiers`
 * See: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#Unique_file_type_specifiers
 *
 * allowMultiple: whether or not to allow the user to upload multiple files at a time (default: false)
 */
export default class FileInput extends React.PureComponent {
  static propTypes = {
    label:     PropTypes.string.isRequired,
    fileTypes: PropTypes.string.isRequired,
    onChange:  PropTypes.func.isRequired,
    title:     PropTypes.string,
    style:     PropTypes.object,
    className: PropTypes.string,
  };

  static defaultProps = {
    value:         [],
    allowMultiple: false,
    showLabel:     true,
  };

  state = {
    isLoading: false,
    error:     undefined,
  };

  _reader = undefined;


  onFileChange = event => {
    const fileData = R.head(R.path(['target', 'files'], event) || []);
    if (R.isNil(fileData)) {
      return;
    }

    if (this._reader) {
      this._reader.abort();
      this._reader = null;
    }

    this._reader = new FileReader();

    this._reader.onabort     = () => this.setState(prev => R.merge(prev, {isLoading: false, error: 'Aborted.'}));
    this._reader.onloadstart = () => this.setState(prev => R.merge(prev, {isLoading: true, error: undefined}));
    this._reader.onload      = event => this.props.onChange({fileData: fileData, blob: event.target.result});
    this._reader.onerror     = ex => this.setState(R.assoc('error', ex.message));
    this._reader.onloadend   = () => this.setState(R.assoc('isLoading', false));

    this._reader.readAsDataURL(fileData);
  };


  render = () => <div className={`form-group ${this.props.className || ''}`}
                      style={R.merge(
                        {
                          display:       'flex',
                          flexDirection: 'column',
                        },
                        this.props.style || {},
                      )}>

    <label htmlFor={`FileInput_${this.props.key}`}>{this.props.label}</label>
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
      <input id={`FileInput_${this.props.key}`}
             accept={this.props.fileTypes}
             title={this.props.title}
             multiple={false}
             onChange={this.onFileChange}
             type='file'/>
      {
        this.state.isLoading && <Spinner style={{width: '25px', height: '25px'}}/>
      }
    </div>
    {
      this.state.error && <span style={{color: 'red'}}>{this.state.error}</span>
    }
  </div>;
}
