import * as React from 'react';
import {Redirect, Route} from 'react-router';
import {HOME} from '../constants/routes';

export const PrivateRoute = ({component: Component, isSignedIn, ...rest}) => {
  const renderFunc = (props) => (
    isSignedIn
    ? <Component {...props} />
    : <Redirect to={{
      pathname: HOME,
      state:    {from: props.location},
    }}/>
  );

  return (<Route {...rest} render={renderFunc}/>);
};
