import './folderView.css';
import backArrow from '../assets/arrowBack.png';
import uploadIcon from '../assets/upload.png';
import folderIcon from '../assets/folder.png';
import pdfIcon from '../assets/pdf.png';
import editIcon from '../assets/edit.png';
import deleteIcon from '../assets/delete.png';

import * as React from 'react';
import {COLORS} from '../colors';
import Spinner from '../components/forms/Spinner/Spinner';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import {isNilOrEmpty} from '../core/language';
import {prettyDate} from '../core/time';
import {cannonicalPath} from './documents';


export default class FolderView extends React.PureComponent {
  static propTypes = {
    loading:       PropTypes.bool.isRequired,
    canEdit:       PropTypes.bool.isRequired,
    error:         PropTypes.string,
    rootDir:       PropTypes.object,
    className:     PropTypes.string,
    style:         PropTypes.object,
    onUploadClick: PropTypes.func,
    onEdit:        PropTypes.func,
    onDelete:      PropTypes.func,
  };

  state = {
    backStack: [this.props.rootDir],
  };


  componentWillUpdate(nextProps, nextState, nextContext) {
    // Dir is changing, reset our cursor TODO: Rewalk tree to make new backstack to current folder
    if (!R.equals(this.props.rootDir, nextProps.rootDir)) {
      this.setState(R.assoc('backStack', [nextProps.rootDir]));
    }
  }


  onUploadClick = () => this.props.onUploadClick(cannonicalPath(R.last(this.state.backStack)));


  makeOnEdit = (fsItem) => (event) => {
    event.stopPropagation();
    this.props.onEdit(fsItem);
  };


  makeOnDelete = (fsItem) => (event) => {
    event.stopPropagation();
    this.props.onDelete(fsItem);
  };


  onBack = () => {
    if (isNilOrEmpty(this.state.backStack) || this.state.backStack.length <= 1) {
      return;
    }

    this.setState(R.assoc('backStack', R.take(this.state.backStack.length - 1, this.state.backStack)));
  };


  makeOnSelectFsItem = item => () => {
    // Open file in new tab
    if (!item.isFolder) {
      const win = window.open(item.Url, '_blank');
      win.focus();
      return;
    }

    this.setState(R.assoc('backStack', this.state.backStack.concat(item)));
  };


  render = () => {
    const currentDir = R.last(this.state.backStack);

    return (
      <div className={this.props.className || ''}
           style={R.merge({backgroundColor: COLORS.INACTIVE_GREY}, this.props.style || {})}>
        {/* Action Bar */}
        <div style={{
          display:        'flex',
          flexDirection:  'row',
          alignItems:     'center',
          justifyContent: 'space-between',
        }}>
          <img src={backArrow}
               alt='Back Arrow'
               title='Back'
               onClick={this.onBack}
               className={`${(this.state.backStack || []).length > 1 ? 'clickable' : 'disabled'} backArrow`}
               style={{padding: '5px'}}/>
          <span title='Current Folder'
                style={{
                  fontSize:   '16px',
                  fontWeight: 'bold',
                }}>{R.prop('name', currentDir) || ''}</span>
          {
            this.props.canEdit
            ? <img src={uploadIcon}
                   alt='Upload'
                   title='Upload a new file'
                   onClick={this.onUploadClick}
                   className={`clickable uploadIcon`}
                   style={{padding: '5px'}}/>
            : <div/>
          }


        </div>

        {/* Folder Contents */}
        <div style={{
          minHeight:       '50px',
          display:         'flex',
          flexDirection:   'column',
          justifyContent:  'stretch',
          backgroundColor: COLORS.SECTION_BACKGROUND,
        }}>
          <div style={{margin: '10px'}}>
            <table style={{width: '100%'}}>
              <thead>
              <tr className='headerRow'>
                <th className='iconColumn headerCell'/>
                <th className='nameColumn headerCell'>Name</th>
                <th className='dateColumn headerCell'>Created</th>
                <th className='dateColumn headerCell'>Last Update</th>
                {
                  this.props.canEdit && <th className='ctaColumn headerCell'/>
                }
              </tr>
              </thead>
              <tbody>
              { // Loading
                this.props.loading && <tr>
                  <td colSpan={this.props.canEdit ? 5 : 4} align="center" style={{padding: '20px'}}>
                    <Spinner style={{
                      width:  '100px',
                      height: '100px',
                    }}/>
                  </td>
                </tr>
              }
              { // Error
                !this.props.loading && this.props.error && <tr>
                  <td colSpan={this.props.canEdit ? 5 : 4} align="center" style={{padding: '20px'}}>
                    <div className="alert alert-danger"><span>{this.props.error}</span></div>
                  </td>
                </tr>
              }
              { // Empty
                !this.props.loading
                && !this.props.error
                && isNilOrEmpty(R.prop('children', currentDir))
                && <tr>
                  <td colSpan={this.props.canEdit ? 5 : 4} align="center" style={{padding: '20px'}}>
                    <div className="alert alert-dark"><span style={{fontWeight: 'bold'}}>Empty Folder</span></div>
                  </td>
                </tr>
              }
              { // Folder contents
                !this.props.loading
                && !this.props.error
                && !isNilOrEmpty(R.prop('children', currentDir))
                && currentDir.children.map(
                  fsItem => <tr
                    onClick={this.makeOnSelectFsItem(fsItem)}
                    className='dataRow'>
                    <td className='iconColumn dataCell'>
                      <img alt={fsItem.isFolder ? 'Folder' : 'PDF'} src={fsItem.isFolder ? folderIcon : pdfIcon}/>
                    </td>
                    <td className='nameColumn dataCell'>{fsItem.name || fsItem.FileName}</td>
                    <td className='dateColumn dataCell'>{prettyDate(fsItem.CreatedOn)}</td>
                    <td className='dateColumn dataCell'>{prettyDate(fsItem.UpdatedOn)}</td>
                    {
                      this.props.canEdit && <td className='ctaColumn dataCell'>
                        <div style={{
                          display:       'flex',
                          flexDirection: 'row',
                        }}>
                          <img className='clickable'
                               title='Edit file'
                               onClick={this.makeOnEdit(fsItem)}
                               alt='pencil'
                               src={editIcon}/>
                          <img className='clickable'
                               title='Delete file'
                               alt='trashcan'
                               onClick={this.makeOnDelete(fsItem)}
                               src={deleteIcon}/>
                        </div>
                      </td>
                    }
                  </tr>,
                )
              }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };
}
