import kodiakIcon from '../assets/kodiakicon.png';
import hardhatIcon from '../assets/hardhatimage.png';
import safetyIcon from '../assets/hrimage.png';
import itIcon from '../assets/itimage.png';

export const categoryTypes = [
    {value: null, display: "Select Category"},
    {value: "general", display: "General", icon: kodiakIcon },
    {value: "safety", display: "Safety", icon: hardhatIcon },
    {value: "hr", display: "Human Resources", icon: safetyIcon },
    {value: "it", display: "Information Technology", icon: itIcon },
];
