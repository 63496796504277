import {RSAA} from 'redux-api-middleware';
import actionTypes from '../appState/actionTypes';
import { ANALYTICS } from '../constants/endpoints';


/**
 * Delegate to the redux api middleware to fetch the mobile app's analytics.
 */
export const fetchAnalytics = () => ({
  [RSAA]: {
    method:   'GET',
    headers:  {
      'content-type': 'application/json',
    },
    endpoint: ANALYTICS,
    types:    [actionTypes.ANALYTICS_GET_REQUEST, actionTypes.ANALYTICS_GET_RESPONSE, actionTypes.ANALYTICS_GET_ERROR],
    // TODO: Add bailout that checks for any pending requests of this type
  },
});


/**
 * Delegate to the redux api middleware to clear the analytics from app state.
 */
export const clearAnalytics = () => ({
  type:    actionTypes.ANALYTICS_CLEAR_EVENTS,
});
