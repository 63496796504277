import confirmModal from "./ConfirmModal";
import deleteModal from "./DeleteModal";
import SignInModal from "./SignInModal";

const modalTypes = {
    confirmModal,
    deleteModal,
    signInModal: SignInModal,
};

export default modalTypes;
