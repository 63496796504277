import {DELETE_FOLDER} from '../constants/endpoints';
import actionTypes from '../appState/actionTypes';
import {RSAA} from 'redux-api-middleware';
import * as R from 'ramda';
import {addLoading, addError, getLoading, removeErrorAndLoading, getApiError} from '../appState/apiState';


const actions = [
  actionTypes.DELETE_FOLDER_REQUEST,
  actionTypes.DELETE_FOLDER_RESPONSE,
  actionTypes.DELETE_FOLDER_ERROR,
];


export const deleteFolderAction = (path, topic) => ({
  [RSAA]: {
    method:   'POST',
    headers:  {'content-type': 'application/json'},
    endpoint: DELETE_FOLDER,
    body:     JSON.stringify({path: path, topic: topic}),
    types:    [
      {
        type: actionTypes.DELETE_FOLDER_REQUEST,
        meta: {path: path},
      },
      {
        type: actionTypes.DELETE_FOLDER_RESPONSE,
        meta: {path: path},
      },
      {
        type: actionTypes.DELETE_FOLDER_ERROR,
        meta: {path: path},
      },
    ],
    bailout:  state => getLoading(state, path, actionTypes.DELETE_FOLDER_REQUEST),
  },
});


export const deleteFolderReducer = (state, action) => {
  if (!actions.includes(action.type)) {
    return state;
  }

  const path      = R.path(['meta', 'path'], action);
  const payload   = R.path(['payload'], action);
  const error     = getApiError(action, payload);
  const isLoading = action.type === actionTypes.DELETE_FOLDER_REQUEST && !error;

  if (isLoading) {
    return addLoading(state, path, actionTypes.DELETE_FOLDER_REQUEST);
  }

  if (error) {
    return addError(state, path, actionTypes.DELETE_FOLDER_REQUEST, `Failed to delete folder: ${error}`);
  }

  // TODO: remove the folder from app state?
  return removeErrorAndLoading(state, path, actionTypes.DELETE_FOLDER_REQUEST);
};
