import PropTypes from 'prop-types';
import React from 'react';
import * as R from 'ramda';
import Navigation from '../Navigation/Navigation';
import { connect } from 'react-redux';
import { fetchAnalytics, clearAnalytics } from '../../actions/analyticsActions';
import { LoadingMessage } from '../ValidationMessages/LoadingMessage';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import { CSVLink } from 'react-csv';
import downloadIconWhite from '../../assets/download-white.png';


export default connect(
  (state, props) => {
    const { isFetching, errorMessage, events } = state.analytics || {
      isFetching:   true,
      errorMessage: '',
      events:       [],
    };
    return R.merge(
      props,
      {
        events,
        isFetching,
        errorMessage,
      },
    );
  },
  { fetchAnalytics, clearAnalytics },
)(
  class Analytics extends React.PureComponent {
    static propTypes = {
      events:         PropTypes.array.isRequired,
      fetchAnalytics: PropTypes.func.isRequired,
      isFetching:     PropTypes.bool.isRequired,
      errorMessage:   PropTypes.string,
    };

    componentDidMount = () => this.props.fetchAnalytics();

    getFileName = () => {
      const date          = new Date(Date.now());
      return `kodiak-mobile-analytics-${date.getMonth()}-${date.getDate()}-${date.getFullYear()}.csv`;
    }

    render = () => (
      <div>
        <Navigation location={this.props.location}/>
        <div className='container' style={{
          marginTop: '20px',
        }}>
          { // Loading
            this.props.isFetching && <LoadingMessage/>
          }
          { // Errored
            this.props.errorMessage && <ErrorMessage message={this.props.errorMessage}/>
          }
          { // Articles
            !this.props.isFetching
            && !this.props.errorMessage
            && <CSVLink data={this.props.events} filename={this.getFileName()} className='btn btn-primary mr-1 ml-1' target='_blank'>
                <span>Download CSV</span>
                <img src={downloadIconWhite}
                     alt='Download'
                     title='Download CSV File'
                     className={`clickable uploadIcon`}
                     style={{ padding: '5px', marginLeft: '10px' }}
                />
            </CSVLink>
          }
        </div>
      </div>
    );
  },
);
